import { Statistic, Tag } from "antd";
import { Col } from "react-bootstrap";
import CountUp from "react-countup";

const formatter = (value) => <CountUp end={value} separator="," />;

export const StatisticCard = ({ title, tagIcon, background, value }) => (
  <Col lg={12} md={12} sm={12} xs={12}>
    <div
      className="d-flex justify-content-center align-items-center rounded-4 p-4"
      style={{
        position: "relative",
        background: background,
      }}
    >
      <Statistic
        title={
          <div className="d-flex align-items-center">
            <span className="fw-bold fs-5 text-black">{title}</span>
            <Tag
              bordered={false}
              color="#12A1A3"
              className="mx-3 d-inline-flex align-items-center justify-content-center text-white rounded-circle"
              style={{
                width: "50px",
                height: "50px",
              }}
            >
              {tagIcon}
            </Tag>
          </div>
        }
        value={value}
        formatter={formatter}
      />
    </div>
  </Col>
);
