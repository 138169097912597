import React, { useCallback, useContext, useEffect, useState } from "react";
import { Container, Col, Image, Row, Card } from "react-bootstrap";
import { Alert, Button, Modal, message } from "antd";
import { FaUserPlus } from "react-icons/fa6";
import { FaShareAlt } from "react-icons/fa";
import {
  EnvironmentOutlined,
  ExclamationCircleFilled,
  GlobalOutlined,
  MailOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import {
  fetchCompanyDetails,
  uploadCardCoverPic,
} from "../../../services/apiServices";

import "./ViewBusinessCardPreviewModal.css";
import {
  createBusinessCard,
  uploadCardProfilePic,
} from "../../../services/apiServices";
import { handleAuthenticationError } from "../../../utils/authHelpers";
import { CompanyContext } from "../../../contexts/CompanyContext";

import {
  CompanyInfoItem,
  SocialMediaButton,
  socialPlatforms,
} from "../../UI/view-digital-card";
import { BiPhone } from "react-icons/bi";
import { ScrollMenu } from "react-horizontal-scrolling-menu";

const ViewBusinessCardPreviewModal = ({
  isVisible,
  onClose,
  data,
  imageUrl,
  fileList,
  coverPicFileList,
  onSuccess,
  bioHtml,
}) => {
  const navigate = useNavigate();

  console.log(fileList);

  const { companyIdCtx, setCompanyIdCtx } = useContext(CompanyContext);

  const [companyDetails, setCompanyDetails] = useState(null);
  const [isCreatingCard, setIsCreatingCard] = useState(false);

  const handleSubmitCreateCard = async () => {
    setIsCreatingCard(true);
    try {
      let uploadedPhotoPath, uploadedCoverPath;

      if (fileList && fileList.length > 0) {
        uploadedPhotoPath = await uploadCardProfilePhoto(
          fileList[0]?.originFileObj
        );
      }

      if (coverPicFileList && coverPicFileList.length > 0) {
        uploadedCoverPath = await uploadCardCoverPhoto(
          coverPicFileList[0]?.originFileObj
        );
      }

      const cardDetails = {
        ...data,
        profile_picture: uploadedPhotoPath || "",
        cover_pic: uploadedCoverPath || "",
        bio: bioHtml || null,
      };

      const response = await createBusinessCard(cardDetails);
      if (response.status === 201) {
        message.success(response.data.message);
        onSuccess(cardDetails);
      } else if (response.status === 401) {
        handleAuthenticationError(response.data.message, navigate);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("Error While Creating Card:", error);
      message.error("Failed to Create Card. Please try again.");
    } finally {
      setIsCreatingCard(false);
      onClose();
    }
  };

  const uploadCardProfilePhoto = async (photoFile) => {
    try {
      const response = await uploadCardProfilePic(photoFile);
      if (response.status === 201) {
        let imagePath = response.data.data;
        return imagePath;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error);
    }
  };

  const uploadCardCoverPhoto = async (photoFile) => {
    try {
      const response = await uploadCardCoverPic(photoFile);
      if (response.status === 201) {
        let imagePath = response.data.data;
        return imagePath;
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      message.error(error);
    }
  };

  const fetchViewCompanyData = useCallback(async () => {
    try {
      const response = await fetchCompanyDetails();
      if (response && response.status === 200) {
        // message.success(response.data.message);
        if (!response.data.data[0]) {
          return message.error("Error While Fetching Company Details");
        } else {
          setCompanyDetails(response.data.data[0]);
        }
      } else if (response.status === 401) {
        handleAuthenticationError(response.data.message, navigate);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("API request failed:", error);
      message.error("Failed to Load Details. Please try again later.");
    } finally {
    }
  }, [navigate, companyIdCtx]);

  useEffect(() => {
    if (isVisible) {
      fetchViewCompanyData();
    }
  }, [isVisible, companyIdCtx, fetchViewCompanyData]);

  return (
    <Modal
      title={
        <>
          <div className="fw-bold fs-5">
            <ExclamationCircleFilled className="mx-2 text-primary text-center" />
            Business Card Preview
          </div>
          <div>
            <Alert
              message="This is Preview of Card to Be Generated. Some Info is Static for View."
              type="warning"
            />
          </div>
        </>
      }
      open={isVisible}
      onCancel={onClose}
      footer={[
        <Button
          danger
          type="primary"
          key="close"
          onClick={onClose}
          size="large"
        >
          Cancel
        </Button>,
        <Button
          type="primary"
          key="close"
          onClick={handleSubmitCreateCard}
          loading={isCreatingCard}
          size="large"
        >
          Create Card
        </Button>,
      ]}
      centered
      destroyOnClose
      closable
      width={650}
    >
      <Container fluid className="p-0 p-lg-5  bg-black rounded-4">
        <div>
          <div className="bg-white rounded-4">
            {/* Profile Image Section */}
            <div className="m-0 p-0 position-relative">
              <Card
                className="m-0 p-0"
                style={{
                  background: companyDetails?.cover_pic
                    ? `url(${companyDetails.cover_pic}) no-repeat center center/cover`
                    : "#1c1c1c", // Default black background
                  minHeight: "300px",
                  color: "#fff",
                  position: "relative",
                }}
              ></Card>
              {fileList?.[0]?.originFileObj && (
                <div
                  className="position-absolute rounded-circle d-flex justify-content-center align-items-center"
                  style={{
                    bottom: "-70px", // Floats outside the card
                    height: "140px",
                    width: "140px",
                    overflow: "hidden",
                    left: "50%",
                    transform: "translateX(-50%)",
                    zIndex: 2,
                  }}
                >
                  <Image
                    src={URL.createObjectURL(fileList[0].originFileObj)}
                    alt="Profile"
                    className="w-100 h-100 rounded-circle"
                    style={{ objectFit: "cover" }}
                  />
                </div>
              )}
            </div>

            {/* Card Details */}
            <div className="mx-2 mx-lg-4" style={{ marginTop: "75px" }}>
              {/* Profile Info Section */}
              <div>
                <div className="text-dark text-wrap mx-4 text-center my-4 mt-5">
                  {
                    <>
                      {data && companyDetails?.company_logo && (
                        <div className="">
                          <Image
                            src={
                              companyDetails?.company_logo ||
                              "https://via.placeholder.com/100"
                            }
                            alt="Company Logo"
                            style={{
                              marginTop: "50vh",
                              width: "auto",
                              height: "50px",
                              objectFit: "cover",
                            }}
                            className="rounded my-2"
                          />
                        </div>
                      )}

                      <h4 className="fw-bold overflow-hidden overflow-wrap">
                        {`${data?.first_name} ${data?.last_name}`}
                      </h4>
                      <h6 className="overflow-wrap">
                        {`${data?.designation}`} at{" "}
                        {`${companyDetails?.company_name}`}
                      </h6>
                    </>
                  }
                </div>
              </div>
              {/* Company Details Section */}
              <div className="my-2">
                {companyDetails?.company_contact_number && (
                  <div className="mb-3">
                    <CompanyInfoItem
                      label="Office Phone"
                      value={companyDetails?.company_contact_number}
                      link={`tel:${companyDetails?.company_contact_number}`}
                      icon={
                        <BiPhone className="d-flex align-items-start justify-content-center" />
                      }
                    />
                  </div>
                )}
                {data?.contact_number && (
                  <div lg={6} md={6} sm={6} xs={6} className="mb-3">
                    <CompanyInfoItem
                      label="Contact No."
                      value={data?.contact_number}
                      link={`tel:${data?.contact_number}`}
                      icon={
                        <BiPhone className="d-flex align-items-start justify-content-center" />
                      }
                    />
                  </div>
                )}
                {companyDetails?.company_address && (
                  <div lg={6} md={6} sm={6} xs={6} className="mb-3">
                    <CompanyInfoItem
                      label="Address"
                      value={companyDetails?.company_address}
                      link={`https://maps.google.com/?q=${companyDetails?.company_address}`}
                      icon={
                        <EnvironmentOutlined className="d-flex align-items-start justify-content-center" />
                      }
                    />
                  </div>
                )}
                {data?.personal_whatsapp && (
                  <div lg={6} md={6} sm={6} xs={6} className="mb-3">
                    <CompanyInfoItem
                      label="Whatsapp"
                      value={data?.personal_whatsapp}
                      link={`https://wa.me/${data?.personal_whatsapp}`}
                      icon={
                        <WhatsAppOutlined className="d-flex align-items-start justify-content-center" />
                      }
                    />
                  </div>
                )}
                {companyDetails?.company_website && (
                  <div lg={6} md={6} sm={6} xs={6} className="mb-3">
                    <CompanyInfoItem
                      label="Corporate Website"
                      value={companyDetails?.company_website}
                      link={companyDetails?.company_website}
                      icon={
                        <GlobalOutlined className="d-flex align-items-start justify-content-center" />
                      }
                    />
                  </div>
                )}
                {data?.user_email && (
                  <div lg={6} md={6} sm={6} xs={6} className="mb-3">
                    <CompanyInfoItem
                      label="Email"
                      value={data?.user_email}
                      link={`mailto:${data?.user_email}`}
                      icon={
                        <MailOutlined className="d-flex align-items-start justify-content-center" />
                      }
                    />
                  </div>
                )}
                {companyDetails?.company_email && (
                  <div lg={6} md={6} sm={6} xs={6} className="mb-3">
                    <CompanyInfoItem
                      label="Office Email"
                      value={companyDetails?.company_email}
                      link={`mailto:${companyDetails?.company_email}`}
                      icon={
                        <MailOutlined className="d-flex align-items-start justify-content-center" />
                      }
                    />
                  </div>
                )}
              </div>
              {/* Social Media Section */}
              <div className="py-2">
                <div
                  className="rounded-4 mx-3"
                  style={{
                    boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <ScrollMenu scrollContainerClassName="no-scrollbar p-2">
                    {socialPlatforms.map((platform) => (
                      <div
                        key={platform}
                        className="d-flex flex-column align-items-center justify-content-center"
                        style={{ minWidth: "100px" }}
                      >
                        <div
                          className="d-flex align-items-center justify-content-center rounded-circle bg-light"
                          style={{ width: "50px", height: "50px" }}
                        >
                          <SocialMediaButton platform={platform} />
                        </div>
                        <small
                          className="text-center text-secondary"
                          style={{ maxWidth: "80px", wordBreak: "break-word" }}
                        >
                          {platform.replace(/[_-]/g, " ").toUpperCase()}
                        </small>
                      </div>
                    ))}
                  </ScrollMenu>
                </div>
              </div>
              {/* Biography and Product/Services */}
              {bioHtml && (
                <div className="my-2">
                  <div
                    className="html_text_render"
                    dangerouslySetInnerHTML={{ __html: bioHtml }}
                  />
                </div>
              )}
              {companyDetails && companyDetails.product_service && (
                <div className="my-2">
                  <div
                    className="html_text_render"
                    dangerouslySetInnerHTML={{
                      __html: companyDetails.product_service,
                    }}
                  />
                </div>
              )}
              {/* Card Share  */}
              <div
                style={{
                  position: "sticky",
                  bottom: 0,
                  zIndex: 1000,
                }}
                className="bg-white p-2"
              >
                <div className="w-100 px-2">
                  <Row className="my-2">
                    <Col lg={4} md={4} sm={4} xs={3}>
                      <Button
                        className="w-100"
                        size="large"
                        icon={<FaShareAlt />}
                      ></Button>
                    </Col>
                    <Col lg={8} md={8} sm={8} xs={9} className="ps-0">
                      <Button
                        className="w-100 text-white"
                        style={{
                          background:
                            "linear-gradient(150deg, #12A1A3, #076263, #023c3c)",
                        }}
                        size="large"
                      >
                        <FaUserPlus className="fs-5" /> Add Contact
                      </Button>
                    </Col>
                  </Row>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Container>
    </Modal>
  );
};

export default ViewBusinessCardPreviewModal;
