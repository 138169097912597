import { message } from "antd";
import { getVCFCardforDigitalCard } from "../../services/apiServices";

export const handleAddToContacts = async (cardDetails) => {
  try {
    const response = await getVCFCardforDigitalCard(cardDetails.id);
    const blob = new Blob([response.data], { type: "text/vcard" });
    const link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.setAttribute("download", "contact.vcf");
    link.click();
  } catch (error) {
    console.error("Error generating vCard:", error);
    message.error(error);
  }
};

export const handleShareButtonClick = async () => {
  try {
    if (navigator.share) {
      await navigator.share({
        title: "View this digital business card and connect with me.",
        text: "View this digital business card and connect with me.",
        url: window.location.href,
      });
    } else {
      throw new Error("Web Share API not supported.");
    }
  } catch (error) {
    console.error("Error sharing:", error.message);
  }
};

export const openSocialLink = (link) => {
  window.open(link, "_blank");
};
