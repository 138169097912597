import React, { useEffect, useState } from "react";
import { Avatar, Button, Layout, Menu, Space, message } from "antd";
import {
  CloseOutlined,
  LogoutOutlined,
  MenuFoldOutlined,
  MenuOutlined,
  MenuUnfoldOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Image, Nav } from "react-bootstrap";
import { useNavigate, Link } from "react-router-dom";

import ViewProfile from "../../../components/Dashboard/ViewProfile/ViewProfile";
import ViewCompanyDetails from "../../../components/Dashboard/ViewCompanyDetails/ViewCompanyDetails";
import ViewCards from "../../../components/Dashboard/ViewCards/ViewCards";
import ViewChangePassword from "../../../components/Dashboard/ViewChangePassword/ViewChangePassword";
import ViewCreateCard from "../../../components/Dashboard/ViewCreateCard/ViewCreateCard";
import ViewCardsQR from "../../../components/Dashboard/ViewCardsQR/ViewCardsQR";
import ViewAddBranch from "../../../components/Dashboard/ViewAddBranch/ViewAddBranch";
import ViewBranchList from "../../../components/Dashboard/ViewBranchList/ViewBranchList";

import { logoutUser } from "../../../services/apiServices";
import "./CompanyDashboardPage.css";
import { AppLogoDark, AppLogoLight } from "../../../utils/constants";
import { dashboardItems } from "../../../components/UI/definations";

const { Content, Sider } = Layout;

const CompanyDashboardPage = () => {
  const navigate = useNavigate();

  const [previousTab, setPreviousTab] = useState(null);
  const [selectedTab, setSelectedTab] = useState(previousTab || "1");
  const [collapsed, setCollapsed] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 992);

  const handleMenuClick = (e) => {
    setPreviousTab(selectedTab);
    setSelectedTab(e.key);
    if (window.innerWidth < 992) {
      setCollapsed(true);
    }
  };

  const renderContent = () => {
    switch (selectedTab) {
      case "1":
        return <ViewCompanyDetails handleMenuClick={handleMenuClick} />;
      case "2":
        return <ViewCreateCard />;
      case "3":
        return <ViewCards />;
      case "4":
        return <ViewCardsQR />;
      case "5":
        return <ViewProfile />;
      case "6":
        return <ViewChangePassword />;
      case "7":
        return <ViewAddBranch />;
      case "8":
        return <ViewBranchList />;
      default:
        return <div>No Content</div>;
    }
  };

  const handleLogout = async () => {
    let isLoggedOut = await logoutUser();

    if (isLoggedOut) {
      navigate("/");
    } else {
      message.error("Logged Out Successfully");
    }
  };

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  useEffect(() => {
    const previousTab = localStorage.getItem("previousTab");
    if (previousTab) {
      setSelectedTab(previousTab);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("previousTab", selectedTab);
  }, [selectedTab]);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 992);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <>
      <div className="MainBg ">
        <Layout className="main-dashboard-layout">
          <Sider
            width={275}
            theme="dark"
            breakpoint="lg"
            collapsedWidth="0"
            collapsed={collapsed}
            onCollapse={(collapsed, type) => {
              setCollapsed(collapsed);
            }}
            style={{
              backgroundColor: "#304244",
              position: "fixed",
              left: 0,
              top: 0,
              bottom: 0,
              zIndex: 1,
            }}
            className="d-flex flex-column"
          >
            {isMobile && (
              <Button
                className="menu-toggle-btn"
                onClick={toggleCollapsed}
                icon={collapsed ? <MenuOutlined /> : <CloseOutlined />}
                style={{
                  backgroundColor: "#272C3B",
                  color: "#fff",
                  border: "none",
                  position: "fixed",
                  top: 16,
                  left: 16,
                  zIndex: 2,
                }}
              />
            )}
            <Space className="app-logo-desktop-view p-3">
              <Image
                src={AppLogoLight}
                width={200}
                className="text-center mx-3 p-1 "
              />
            </Space>
            <Menu
              theme="dark"
              mode="inline"
              defaultSelectedKeys={[selectedTab]}
              onClick={handleMenuClick}
              style={{ backgroundColor: "#304244" }}
              className="mb-2 p-4"
            >
              {dashboardItems.map((item) => (
                <Menu.Item
                  key={item.key}
                  icon={item.icon}
                  className="sider-dashboard-menu-item my-2 fw-semibold"
                >
                  {item.label}
                </Menu.Item>
              ))}
              <Menu.Item
                key="5"
                icon={<Avatar size={40} icon={<UserOutlined />} />}
                className="sider-dashboard-menu-item my-2 fw-semibold py-4"
              >
                Profile
              </Menu.Item>
            </Menu>

            <div className="mt-auto p-4">
              <Button
                danger
                type="primary"
                className="w-50 d-flex  mx-5 align-items-center justify-content-center"
                onClick={handleLogout}
                style={{
                  backgroundColor: "#12a1a3",
                  borderColor: "#12a1a3",
                }}
              >
                Logout
              </Button>
            </div>
          </Sider>
          <Layout>
            <Content
              style={{
                backgroundColor: "#F5F4F8",
                minHeight: "100vh",
                overflowY: "auto",
                marginLeft: collapsed ? 0 : 275,
                transition: "margin-left 0.3s",
              }}
            >
              <div
                style={{
                  padding: 16,
                  borderRadius: "16px",
                }}
                className="dashboard-container"
              >
                {renderContent()}
              </div>
            </Content>
          </Layout>
        </Layout>
      </div>
    </>
  );
};

export default CompanyDashboardPage;
