import { message } from "antd";

// Utility function to handle file change with enhanced error messaging
export const handleFileChange =
  (setFileState, setPreviewState, onErrorCallback) =>
  ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const file = newFileList[0].originFileObj;
      const validTypes = ["image/jpeg", "image/png", "image/jpg"];

      if (!validTypes.includes(file.type)) {
        const fileType = file.type ? file.type.split("/")[1] : "unknown";
        const validTypesList = validTypes
          .map((type) => type.split("/")[1])
          .join(", ");
        message.error(
          `Invalid type ${fileType}. Valid types are ${validTypesList}.`
        );
        return;
      }

      const previewUrl = URL.createObjectURL(file);
      setPreviewState(previewUrl);
    } else {
      setPreviewState(null);
    }
    setFileState(newFileList);
  };

// Utility function to handle file preview
export const handleFilePreview = async (file) => {
  let src = file.url;
  if (!src) {
    src = await new Promise((resolve) => {
      const reader = new FileReader();
      reader.readAsDataURL(file.originFileObj);
      reader.onload = () => resolve(reader.result);
    });
  }
  const image = new Image();
  image.src = src;
  const imgWindow = window.open(src);
  imgWindow?.document.write(image.outerHTML);
};
