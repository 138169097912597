import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, message, Spin, Input, Table } from "antd";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import { PlusOutlined } from "@ant-design/icons";

import "./ViewCards.css";
import {
  exportCardsDetailsFile,
  fetchCardsList,
} from "../../../services/apiServices";
import { handleAuthenticationError } from "../../../utils/authHelpers";

import ActivateCardQR from "./ActivateCardQR";
import DeactivateCard from "./DeactivateCard";
import ActivateMultipleCardsQR from "./ActivateMultipleCardsQR";
import DeleteCard from "./DeleteCard";
import MidinFooter from "../../MidinFooter/MidinFooter";
import EditCard from "./EditCard";
import { viewCardsColumns } from "../../UI/table-columns";
import { QRDetailsModal } from "../../UI/dialog-modal";
import BreadcrumbComponent from "../../UI/bread-crumb";

function ViewCards({ setShowEditCard }) {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isFetchingCards, setIsFetchingCards] = useState(false);
  const [CardsData, setCardsData] = useState([]);
  const [showActivateCardQRModal, setShowActivateCardQRModal] = useState(false);
  const [showDeactivateCardModal, setShowDeactivateCardModal] = useState(false);
  const [showActivateMultipleModal, setShowActivateMultipleModal] =
    useState(false);
  const [showDeleteCardModal, setShowDeleteCardModal] = useState(false);
  const [showEditCardModal, setShowEditCardModal] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [pageSize, setPageSize] = useState(5);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedUserData, setSelectedUserData] = useState(null);

  const fetchViewCardsData = useCallback(async () => {
    try {
      setIsFetchingCards(true);
      const response = await fetchCardsList();
      if (response && response.status === 200) {
        setCardsData(response.data.data);
        setFilteredData(response.data.data);
        form.setFieldsValue(response.data.data);
        navigate("/dashboard");
      } else if (response.status === 401) {
        handleAuthenticationError(response.data.message, navigate);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("API request failed:", error);
      message.error("Failed to Load Details. Please try again later.");
      setIsFetchingCards(false);
    } finally {
      setIsFetchingCards(false);
    }
  }, [navigate, form]);

  useEffect(() => {
    fetchViewCardsData();
  }, [navigate, fetchViewCardsData]);

  const handleSearch = (value) => {
    setSearchQuery(value);
    filterData(value);
  };

  const filterData = (value) => {
    const filtered = CardsData.filter((record) =>
      Object.keys(record).some((key) => {
        const fieldValue = record[key];
        if (fieldValue && typeof fieldValue === "string") {
          return fieldValue.toLowerCase().includes(value.toLowerCase());
        }
        return false;
      })
    );
    setFilteredData(filtered);
  };

  const handleDeactivateCardModalOpen = (record) => {
    setSelectedRecord(record);
    setShowDeactivateCardModal(true);
  };

  const handleDeleteCardModalOpen = (record) => {
    setSelectedRecord(record);
    setShowDeleteCardModal(true);
  };

  const handleEditCardModalOpen = (record) => {
    setSelectedRecord(record);
    setShowEditCardModal(true);
  };

  const handleActivateCardQRModalClose = () => {
    setShowActivateCardQRModal(false);
  };

  const handleDeactivateCardModalClose = () => {
    setShowDeactivateCardModal(false);
  };

  const handleActivateMultipleCardModalClose = () => {
    setShowActivateMultipleModal(false);
  };

  const handleDeleteCardModalClose = () => {
    setShowDeleteCardModal(false);
  };

  const handleEditModalClose = () => {
    setShowEditCardModal(false);
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const handleViewQR = (record) => {
    setSelectedUserData(record);
    setModalVisible(true);
  };

  const handleModalClose = () => {
    setModalVisible(false);
    setSelectedUserData(null);
  };

  const components = {
    header: {
      cell: (props) => (
        <th {...props} style={{ backgroundColor: "-moz-initial" }} />
      ),
    },
  };

  const handleExportCards = async () => {
    try {
      const response = await exportCardsDetailsFile();

      if (!response.data.fileUrl) {
        throw new Error("File URL is missing in the response.");
      }

      const fileUrl = response.data.fileUrl;

      const downloadLink = document.createElement("a");
      downloadLink.href = fileUrl;
      downloadLink.setAttribute("download", "card_details.xlsx");

      document.body.appendChild(downloadLink);
      downloadLink.click();

      document.body.removeChild(downloadLink);
      message.success("File downloaded successfully.");
    } catch (error) {
      console.error("Error downloading file:", error.message);
      message.error("Error while downloading file: " + error.message);
    }
  };

  return (
    <>
      <BreadcrumbComponent
        breadcrumbItems={[
          { title: "Home" },
          { title: "Cards", isActive: true },
        ]}
      />
      <h2 className="mx-4">Cards</h2>
      <Row className="mx-4 cards-search-button">
        <Col lg={8} md={8} sm={12} xs={12} className="m-0 p-0">
          <Input.Search
            placeholder="Search by Name, Email, Contact or Card Reference"
            allowClear
            enterButton
            onSearch={handleSearch}
            onKeyUp={(e) => handleSearch(e.target.value)}
            style={{
              marginBottom: 16,
            }}
            size="large"
          />
        </Col>

        <Col lg={2} md={6} sm={6} xs={0}>
          <Button
            className="mb-3 w-100"
            onClick={handleExportCards}
            size="large"
            style={{ backgroundColor: "#E4E4E4", borderColor: "#E4E4E4" }}
          >
            {CardsData.length} cards used
          </Button>
        </Col>
        <Col lg={2} md={6} sm={6} xs={0}>
          <Button
            type="primary"
            className="mb-3 mx-0 w-100"
            onClick={handleExportCards}
            size="large"
            style={{
              backgroundColor: "#12A1A3",
              borderColor: "#12A1A3 ",
              marginLeft: "-30px",
            }}
          >
            <PlusOutlined /> Export Cards
          </Button>
        </Col>
        <Col lg={2} md={2} sm={0} xs={0}></Col>
      </Row>

      <div className="viewCardsContainer mx-4">
        <Spin spinning={isFetchingCards} size="large">
          <Table
            rowKey={(record) => record.id}
            bordered
            size="large"
            pagination={{
              position: ["bottomRight"],
              pageSize,
              pageSizeOptions: ["5", "10", "20", "50"],
              showSizeChanger: true,
              onShowSizeChange: handlePageSizeChange,
              itemRender: (page, type, originalElement) => {
                if (type === "prev") {
                  return (
                    <Button
                      type="default"
                      style={{
                        backgroundColor: "#12A1A3",
                        borderColor: "#12A1A3 ",
                        color: "white",
                      }}
                    >
                      Prev
                    </Button>
                  );
                }
                if (type === "next") {
                  return (
                    <Button
                      type="default"
                      style={{
                        backgroundColor: "#12A1A3",
                        borderColor: "#12A1A3 ",
                        color: "white",
                      }}
                    >
                      Next
                    </Button>
                  );
                }
                return originalElement;
              },
            }}
            columns={viewCardsColumns({
              handleDeactivateCardModalOpen,
              handleDeleteCardModalOpen,
              handleEditCardModalOpen,
              handleViewQR,
            })}
            dataSource={filteredData}
            scroll={{
              x: "max-content",
            }}
            components={components}
          />
        </Spin>
      </div>

      <MidinFooter />
      <ActivateCardQR
        visible={showActivateCardQRModal}
        onOk={(status) => {
          handleActivateCardQRModalClose();
          fetchViewCardsData();
        }}
        onCancel={() => setShowActivateCardQRModal(false)}
        record={selectedRecord}
      />
      <DeactivateCard
        visible={showDeactivateCardModal}
        onOk={(status) => {
          handleDeactivateCardModalClose();
          fetchViewCardsData();
        }}
        onCancel={() => setShowDeactivateCardModal(false)}
        record={selectedRecord}
      />
      <ActivateMultipleCardsQR
        visible={showActivateMultipleModal}
        onOk={(status) => {
          handleActivateMultipleCardModalClose();
          fetchViewCardsData();
        }}
        onCancel={() => setShowActivateMultipleModal(false)}
      />
      <DeleteCard
        visible={showDeleteCardModal}
        onOk={(status) => {
          handleDeleteCardModalClose();
          fetchViewCardsData();
        }}
        onCancel={() => setShowDeleteCardModal(false)}
        record={selectedRecord}
      />
      <EditCard
        visible={showEditCardModal}
        onCancel={handleEditModalClose}
        onEditSuccess={(info) => {
          handleEditModalClose();
          fetchViewCardsData();
        }}
        record={selectedRecord}
      />
      <QRDetailsModal
        visible={modalVisible}
        onCancel={handleModalClose}
        selectedUserData={selectedUserData}
      />
    </>
  );
}

export default ViewCards;
