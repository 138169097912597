// CardShare.js
// import { Button } from "antd";
// import React from "react";
// import { Row, Col } from "react-bootstrap";
// import { FaShareAlt, FaUserPlus } from "react-icons/fa";

// const CardShare = ({
//   handleShareButtonClick,
//   handleAddToContacts,
//   cardDetails,
// }) => (
//   <div className="w-100">
//     <Row className="my-4">
//       <Col lg={4} md={4} sm={4} xs={3}>
//         <Button
//           className="w-100"
//           size="large"
//           icon={<FaShareAlt />}
//           onClick={handleShareButtonClick}
//         ></Button>
//       </Col>
//       <Col lg={8} md={8} sm={8} xs={9} className="ps-0">
//         <Button
//           className="w-100 text-white"
//           style={{
//             background: "linear-gradient(150deg, #12A1A3, #076263, #023c3c)",
//           }}
//           size="large"
//           onClick={() => handleAddToContacts(cardDetails)}
//         >
//           <FaUserPlus className="fs-5" /> Add Contact
//         </Button>
//       </Col>
//     </Row>
//   </div>
// );

// export default CardShare;

// CardShare.js
import { Button } from "antd";
import React from "react";
import { Row, Col } from "react-bootstrap";
import { FaShareAlt, FaUserPlus } from "react-icons/fa";

const CardShare = ({
  handleShareButtonClick,
  handleAddToContacts,
  cardDetails,
}) => (
  <div className="w-100 px-2 rounded-4">
    <Row className="my-2">
      <Col lg={4} md={4} sm={4} xs={3}>
        <Button
          className="w-100"
          size="large"
          icon={<FaShareAlt />}
          onClick={handleShareButtonClick}
        ></Button>
      </Col>
      <Col lg={8} md={8} sm={8} xs={9} className="ps-0">
        <Button
          className="w-100 text-white"
          style={{
            background: "linear-gradient(150deg, #12A1A3, #076263, #023c3c)",
          }}
          size="large"
          onClick={() => handleAddToContacts(cardDetails)}
        >
          <FaUserPlus className="fs-5" /> Add Contact
        </Button>
      </Col>
    </Row>
  </div>
);

export default CardShare;
