import {
  Avatar,
  Button,
  Form,
  Input,
  message,
  Spin,
  Upload,
  Modal,
  Statistic,
  Tag,
} from "antd";
import React, { useCallback, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";

import "./ViewProfile.css";
import { useNavigate } from "react-router-dom";
import {
  editProfile,
  fetchViewProfile,
  uploadAvatar,
} from "../../../services/apiServices";
import { Col, Row } from "react-bootstrap";
import {
  ContactsOutlined,
  ExclamationCircleFilled,
  MailOutlined,
  PhoneOutlined,
  ProfileOutlined,
  RightOutlined,
  UploadOutlined,
  UserAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import ImgCrop from "antd-img-crop";
import { handleAuthenticationError } from "../../../utils/authHelpers";
import MidinFooter from "../../MidinFooter/MidinFooter";
import { validationRules } from "../../../utils/validationRules";
import "./ViewProfile.css";

import { FaHouseChimney } from "react-icons/fa6";
import BreadcrumbComponent from "../../UI/bread-crumb";

const { Item } = Form;

function ViewProfile() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isFetchingProfile, setIsFetchingProfile] = useState(false);
  const [isUpdatingProfile, setIsUpdatingProfile] = useState(false);
  const [profileData, setProfileData] = useState({});
  const [avatarPreview, setAvatarPreview] = useState("");
  const [fileList, setFileList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onChange" });

  const onFinishSubmit = async (values) => {
    setIsUpdatingProfile(true);
    try {
      const uploadedPhotoPath = await handleProfilePicUpdate(
        fileList[0]?.originFileObj
      );

      const updatedValues = {
        ...values,
        avatar: uploadedPhotoPath,
      };

      const response = await editProfile(updatedValues);
      if (response && response.status === 200) {
        message.success(response.data.message);
        fetchViewProfileData();
      } else if (response.status === 401) {
        handleAuthenticationError(response.data.message, navigate);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("API request failed:", error);
      message.error("Failed to update profile. Please try again later.");
      setIsUpdatingProfile(false);
    } finally {
      setIsUpdatingProfile(false);
    }
  };

  const handleProfilePicUpdate = async (imageFile) => {
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj;

      const response = await uploadAvatar(imageFile);
      if (response.status === 201) {
        let imagePath = response.data.data;
        return imagePath;
      } else {
        message.error(response.data.message);
        return;
      }
    } else {
      return profileData.avatar || avatarPreview;
    }
  };

  const fetchViewProfileData = useCallback(async () => {
    try {
      setIsFetchingProfile(true);
      const response = await fetchViewProfile();
      if (response && response.status === 200) {
        setProfileData(response.data.data);
        setAvatarPreview(response.data.data.avatar);

        form.setFieldsValue(response.data.data);
        navigate("/dashboard");
      } else if (response.status === 401) {
        handleAuthenticationError(response.data.message, navigate);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("API request failed:", error);
      message.error("Failed to Load Details. Please try again later.");
      setIsFetchingProfile(false);
    } finally {
      setIsFetchingProfile(false);
    }
  }, [navigate, form]);

  useEffect(() => {
    fetchViewProfileData();
  }, [navigate, fetchViewProfileData]);

  const onChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const newAvatarPreview = URL.createObjectURL(
        newFileList[0].originFileObj
      );
      setAvatarPreview(newAvatarPreview);
    } else {
      setAvatarPreview("");
    }
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const confirmSaveChanges = () => {
    setIsModalVisible(false);
    form.submit();
  };

  const removeProfilePic = async () => {
    setAvatarPreview(null); // Remove the avatar preview
    setFileList([]); // Clear the file list

    // Update the profile data with null for the avatar field
    const updatedProfileData = { ...profileData, avatar: null };
    setProfileData(updatedProfileData);

    try {
      // If needed, call the API to update the profile with the new avatar as null
      const response = await editProfile(updatedProfileData);
      if (response && response.status === 200) {
        message.success(response.data.message);
        fetchViewProfileData(); // Optionally, refetch profile data
      } else if (response.status === 401) {
        handleAuthenticationError(response.data.message, navigate);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("API request failed:", error);
      message.error(
        "Failed to remove profile picture. Please try again later."
      );
    }
  };

  return (
    <>
      <Spin spinning={isFetchingProfile} size="large">
        <BreadcrumbComponent
          breadcrumbItems={[
            { title: "Home" },
            { title: "Profile", isActive: true },
          ]}
        />
        <h2 className="mx-4 ">Profile</h2>

        <div className="viewProfileContainer">
          <Form
            form={form}
            layout="vertical"
            onFinish={onFinishSubmit}
            name="editProfileDetailForm"
          >
            <Row>
              <Col
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="d-flex justify-content-lg-start mb-4 justify-content-center"
              >
                <div
                  className="d-flex justify-content-center w-75 align-items-center rounded-4 p-3"
                  style={{
                    position: "relative",
                    background:
                      "linear-gradient(205.92deg, #CBE1DC -12.96%, #87D8F9 134.13%)",
                    maxWidth: "100%",
                  }}
                >
                  <Statistic
                    title={
                      <div
                        className="d-flex align-items-center flex-wrap"
                        style={{
                          overflowWrap: "break-word",
                          wordWrap: "break-word",
                        }}
                      >
                        <span
                          className="fw-bold fs-5 text-black text-center"
                          style={{
                            fontSize: "1rem",
                            whiteSpace: "normal",
                          }}
                        >
                          Company Name
                        </span>
                        <Tag
                          bordered={false}
                          color="#12A1A3"
                          className="mx-3 d-inline-flex align-items-center justify-content-center text-white rounded-circle"
                          style={{
                            width: "40px",
                            height: "40px",
                          }}
                        >
                          <FaHouseChimney className="fs-4" />
                        </Tag>
                      </div>
                    }
                    value={profileData?.company_name || "N/A"}
                  />
                </div>
              </Col>
              <Col
                lg={6}
                md={6}
                sm={12}
                xs={12}
                className="d-flex flex-column align-items-start mb-4 justify-content-lg-start justify-content-center"
              >
                <label className="fw-bold my-1">Profile Photo</label>
                <br />
                <ImgCrop rotationSlider showReset>
                  <Upload
                    accept="image/*"
                    onChange={onChange}
                    onPreview={onPreview}
                    showUploadList={false}
                    fileList={fileList}
                    maxCount={1}
                    customRequest={({ file, onSuccess }) => {
                      setTimeout(() => {
                        onSuccess("ok");
                      }, 0);
                    }}
                  >
                    {fileList.length < 1 && !avatarPreview && (
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        className="border border-2 my-4 p-2 rounded-4 d-flex align-items-center justify-content-center"
                      >
                        <UploadOutlined
                          style={{
                            fontSize: "24px",
                            marginRight: "8px",
                            cursor: "pointer",
                          }}
                        />
                        Upload Profile Picture
                      </div>
                    )}

                    {avatarPreview && (
                      <label htmlFor="avatar-upload" className="d-block">
                        <Avatar
                          id="avatar-upload"
                          size={120}
                          src={avatarPreview}
                          alt="User Profile Picture"
                          className="rounded-circle border border-2"
                          style={{
                            border: "none",
                            padding: "0",
                            background: "transparent",
                          }}
                        />
                      </label>
                    )}
                  </Upload>
                </ImgCrop>
                {avatarPreview && (
                  <Button type="link" danger onClick={removeProfilePic}>
                    Remove Profile Photo
                  </Button>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={12} sm={6}>
                <label className="fw-bold my-1">First Name</label>
                <Item
                  name="first_name"
                  rules={validationRules.first_name}
                  hasFeedback
                >
                  <Input
                    size="large"
                    placeholder="Enter Your First Name"
                    defaultValue={profileData.first_name || "N/A"}
                    prefix={
                      <UserAddOutlined
                        style={{ color: "#595959" }}
                        className="mx-1"
                      />
                    }
                  />
                </Item>
              </Col>
              <Col lg={6} md={12} sm={6}>
                <label className="fw-bold my-1">Last Name</label>
                <Item
                  name="last_name"
                  rules={validationRules.last_name}
                  hasFeedback
                >
                  <Input
                    size="large"
                    placeholder="Enter Your Last Name"
                    defaultValue={profileData.last_name || "N/A"}
                    prefix={
                      <UserOutlined
                        style={{ color: "#595959" }}
                        className="mx-1"
                      />
                    }
                  />
                </Item>
              </Col>
              <Col lg={6} md={12} sm={6}>
                <label className="fw-bold my-1">Email</label>
                <Item name="email" rules={validationRules.email} hasFeedback>
                  <Input
                    size="large"
                    placeholder="Enter Your Email"
                    defaultValue={profileData.email || "N/A"}
                    prefix={
                      <MailOutlined
                        style={{ color: "#595959" }}
                        className="mx-1"
                      />
                    }
                  />
                </Item>
              </Col>

              <Col lg={6} md={12} sm={6}>
                <label className="fw-bold my-1">Company Name</label>
                <Item
                  name="company_name"
                  rules={validationRules.last_name}
                  hasFeedback
                >
                  <Input
                    size="large"
                    placeholder="Enter company Name "
                    defaultValue={profileData.company_name || "N/A"}
                    prefix={
                      <ProfileOutlined
                        style={{ color: "#595959" }}
                        className="mx-1"
                      />
                    }
                  />
                </Item>
              </Col>
              <Col lg={6} md={12} sm={6}>
                <label className="fw-bold my-1">Mobile Number</label>
                <Item
                  name="mobile_number"
                  rules={validationRules.mobile_number}
                  hasFeedback
                >
                  <Input
                    size="large"
                    placeholder="Enter Your Mobile Number"
                    defaultValue={profileData.mobile_number || "N/A"}
                    prefix={
                      <PhoneOutlined
                        style={{ color: "#595959" }}
                        className="mx-1"
                      />
                    }
                  />
                </Item>
              </Col>

              <Col lg={6} md={12} sm={6}>
                <label className="fw-bold my-1">Phone Number</label>
                <Item
                  name="phone_number"
                  rules={validationRules.phone_number}
                  hasFeedback
                >
                  <Input
                    size="large"
                    placeholder="Enter Your Phone Number"
                    defaultValue={profileData.phone_number || "N/A"}
                    prefix={
                      <ContactsOutlined
                        style={{ color: "#595959" }}
                        className="mx-1"
                      />
                    }
                  />
                </Item>
              </Col>
            </Row>
            <Row>
              <Col lg={4} md={4} sm={3} className="text-center"></Col>
              <Col lg={4} md={4} sm={6} className="text-center">
                <Item>
                  <Button
                    type="primary"
                    className="w-100"
                    size="large"
                    // htmlType="submit"
                    loading={isUpdatingProfile}
                    onClick={showModal}
                    style={{
                      backgroundColor: "#4D81E3",
                      color: "white",
                    }}
                  >
                    Save Changes
                  </Button>
                </Item>
              </Col>
              <Col lg={4} md={4} sm={3} className="text-center"></Col>
            </Row>
          </Form>
        </div>
      </Spin>

      <MidinFooter />

      <Modal
        centered
        title={
          <span className="fw-bold fs-5">
            <ExclamationCircleFilled className="mx-2 text-warning" />
            Do you Want to Save Changes?
          </span>
        }
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <div className="text-center">
            <Button
              key="cancel"
              onClick={handleCancel}
              size="large"
              danger
              className="me-2"
            >
              Cancel
            </Button>
            <Button
              key="confirm"
              type="primary"
              onClick={confirmSaveChanges}
              size="large"
              className="ms-2"
            >
              Confirm
            </Button>
          </div>,
        ]}
      ></Modal>
    </>
  );
}

export default ViewProfile;
