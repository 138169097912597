// CompanyDetails.js
import React from "react";
import { Spin } from "antd";
import {
  EnvironmentOutlined,
  GlobalOutlined,
  MailOutlined,
  PhoneOutlined,
  WhatsAppOutlined,
} from "@ant-design/icons";
import { BiPhone } from "react-icons/bi";

import { WhatsAppCustomIcon } from "../../utils/constants";
import { CompanyInfoItem } from "../../components/UI/view-digital-card";

const CompanyDetails = ({ cardDetails, isFetchingCard }) => (
  <div className="viewCardCompanyInfo">
    {!isFetchingCard ? (
      <>
        {cardDetails?.company_contact_number && (
          <div className="mb-3">
            <CompanyInfoItem
              label="Office Phone"
              value={cardDetails?.company_contact_number}
              link={`tel:${cardDetails?.company_contact_number}`}
              icon={
                <BiPhone className="d-flex align-items-start justify-content-center" />
              }
            />
          </div>
        )}
        {cardDetails?.contact_number && (
          <div lg={6} md={6} sm={6} xs={6} className="mb-3">
            <CompanyInfoItem
              label="Contact No."
              value={cardDetails?.contact_number}
              link={`tel:${cardDetails?.contact_number}`}
              icon={
                <BiPhone className="d-flex align-items-start justify-content-center" />
              }
            />
          </div>
        )}
        {cardDetails?.company_address && (
          <div lg={6} md={6} sm={6} xs={6} className="mb-3">
            <CompanyInfoItem
              label="Address"
              value={cardDetails?.company_address}
              link={`https://maps.google.com/?q=${cardDetails?.company_address}`}
              icon={
                <EnvironmentOutlined className="d-flex align-items-start justify-content-center" />
              }
            />
          </div>
        )}
        {cardDetails?.personal_whatsapp && (
          <div lg={6} md={6} sm={6} xs={6} className="mb-3">
            <CompanyInfoItem
              label="Whatsapp"
              value={cardDetails?.personal_whatsapp}
              link={`https://wa.me/${cardDetails?.personal_whatsapp}`}
              icon={
                <WhatsAppOutlined className="d-flex align-items-start justify-content-center" />
              }
            />
          </div>
        )}
        {cardDetails?.company_website && (
          <div lg={6} md={6} sm={6} xs={6} className="mb-3">
            <CompanyInfoItem
              label="Corporate Website"
              value={cardDetails?.company_website}
              link={cardDetails?.company_website}
              icon={
                <GlobalOutlined className="d-flex align-items-start justify-content-center" />
              }
            />
          </div>
        )}
        {cardDetails?.user_email && (
          <div lg={6} md={6} sm={6} xs={6} className="mb-3">
            <CompanyInfoItem
              label="Email"
              value={cardDetails?.user_email}
              link={`mailto:${cardDetails?.user_email}`}
              icon={
                <MailOutlined className="d-flex align-items-start justify-content-center" />
              }
            />
          </div>
        )}
        {cardDetails?.company_email && (
          <div lg={6} md={6} sm={6} xs={6} className="mb-3">
            <CompanyInfoItem
              label="Office Email"
              value={cardDetails?.company_email}
              link={`mailto:${cardDetails?.company_email}`}
              icon={
                <MailOutlined className="d-flex align-items-start justify-content-center" />
              }
            />
          </div>
        )}
      </>
    ) : (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "25vh" }}
      >
        <Spin spinning={isFetchingCard} size="large" />
      </div>
    )}
  </div>
);

export default CompanyDetails;
