import { Skeleton } from "antd";
import React from "react";
import { Image } from "react-bootstrap";

const ProfileInfo = ({ cardDetails, isFetchingCard }) => {
  return (
    <div>
      <div className="text-dark text-wrap mx-4 text-center my-4 mt-5">
        {!isFetchingCard ? (
          <>
            {cardDetails && cardDetails?.company_logo && (
              <div className="">
                <Image
                  src={cardDetails?.company_logo}
                  alt="Company Logo"
                  style={{ width: "auto", height: "50px", objectFit: "cover" }}
                  className="rounded my-2"
                />
              </div>
            )}

            <h4 className="fw-bold overflow-hidden overflow-wrap">
              {`${cardDetails?.first_name} ${cardDetails?.last_name}`}
            </h4>
            <h6 className="overflow-wrap">{`${cardDetails?.designation}`}</h6>
            <h6 className="fst-italic overflow-wrap">{`${cardDetails?.company_name}`}</h6>
          </>
        ) : (
          <>
            <Skeleton
              active
              title={{ width: "100%" }}
              paragraph={{ rows: 2, width: ["100%", "100%"] }}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default ProfileInfo;
