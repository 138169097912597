// ViewBranchList.jsx
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Spin, Select, Table, message, Tag, Button } from "antd";
import { useNavigate } from "react-router-dom";
import { handleAuthenticationError } from "../../../utils/authHelpers";
import { CompanyContext } from "../../../contexts/CompanyContext";

import {
  getCompanyDetailsLists,
  setDefaultAddress,
} from "../../../services/apiServices";
import { viewBranchListColumns } from "../../UI/table-columns";
import { Col, Row } from "react-bootstrap";
import EditBranchModal from "./EditBranchModal";
import BreadcrumbComponent from "../../UI/bread-crumb";

const { Option } = Select;

const ViewBranchList = () => {
  const navigate = useNavigate();
  const { companyIdCtx } = useContext(CompanyContext);
  const [loading, setLoading] = useState(true);
  const [branches, setBranches] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [currentBranch, setCurrentBranch] = useState(null);
  const [pageSize, setPageSize] = useState(5);

  const getBranches = useCallback(async () => {
    try {
      const response = await getCompanyDetailsLists();
      if (response.status === 200) {
        setBranches(response.data.data);
      } else if (response.status === 401) {
        handleAuthenticationError(response.data.message, navigate);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("API request failed:", error);
      message.error("Failed to fetch branches. Please try again later.");
    } finally {
      setLoading(false);
    }
  }, [navigate]);

  const handleEdit = (branch) => {
    setCurrentBranch(branch);
    setIsModalVisible(true);
  };

  const handleSetDefaultBranch = async (companyId) => {
    try {
      const response = await setDefaultAddress(companyId);
      if (response.status === 200) {
        message.success("Default Branch Set Successfully");
        getBranches();
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("Failed to set default branch:", error);
      message.error("Failed to set default branch. Please try again later.");
    }
  };

  const handlePageSizeChange = (current, size) => {
    setPageSize(size);
  };

  const handleSuccess = async (success) => {
    if (success === true) {
      setIsModalVisible(false);
      getBranches();
    } else {
      setIsModalVisible(false);
    }
  };

  const columns = viewBranchListColumns({ handleEdit, getBranches, navigate });

  const renderOptionLabel = (branch) => (
    <span>
      <Row>
        <Col lg={9} md={9} sm={9} xs={9}>
          <div className=" text-wrap">
            {branch.company_name} - {branch.company_address}
          </div>
        </Col>
        <Col lg={3} md={3} sm={3} xs={3}>
          <div>
            {branch.is_default_address && (
              <Tag color="processing" className="mx-2">
                Default Address
              </Tag>
            )}
          </div>
        </Col>
      </Row>
    </span>
  );

  useEffect(() => {
    getBranches();
  }, [companyIdCtx, navigate, getBranches]);

  return (
    <>
      <Spin spinning={loading} size="large">
        <BreadcrumbComponent
          breadcrumbItems={[
            { title: "Home" },
            { title: "Branch List", isActive: true },
          ]}
        />
        <h2 className="mx-4">Branch List</h2>
        <div className="viewCompanyDetailsContainer mx-4">
          <Row>
            <Col lg={6} md={6} sm={12} xs={12}>
              <Select
                showSearch
                style={{ width: "100%", height: "max-content" }}
                placeholder="Select a Branch"
                size="large"
                className="mb-3"
                optionFilterProp="label"
                filterOption={(input, option) =>
                  option.label.toLowerCase().includes(input.toLowerCase())
                }
                filterSort={(optionA, optionB) =>
                  optionA.label
                    .toLowerCase()
                    .localeCompare(optionB.label.toLowerCase())
                }
                onChange={handleSetDefaultBranch}
              >
                {branches &&
                  branches.map((branch, index) => (
                    <Option
                      key={branch.id}
                      value={branch.id}
                      label={branch.company_name}
                    >
                      {renderOptionLabel(branch)}
                    </Option>
                  ))}
              </Select>
            </Col>
            {/* <Col lg={3} md={6} sm={6}>
              <Button
                type="primary"
                className="mb-3 w-100 "
                size="large"
                style={{ backgroundColor: "#12A1A3", borderColor: "#12A1A3" }}
              >
                <PlusOutlined /> Add New Branch
              </Button>
            </Col> */}
            <Col lg={3} md={3} sm={12} xs={12}></Col>
          </Row>
          <div>
            <Table
              columns={columns}
              dataSource={branches}
              rowKey="id"
              pagination={{
                position: ["bottomRight"],
                pageSize,
                pageSizeOptions: ["5", "10", "20", "50"],
                showSizeChanger: true,
                onShowSizeChange: handlePageSizeChange,
                itemRender: (page, type, originalElement) => {
                  if (type === "prev") {
                    return (
                      <Button
                        type="default"
                        style={{
                          backgroundColor: "#12A1A3",
                          borderColor: "#12A1A3 ",
                          color: "white",
                        }}
                      >
                        Prev
                      </Button>
                    );
                  }
                  if (type === "next") {
                    return (
                      <Button
                        type="default"
                        style={{
                          backgroundColor: "#12A1A3",
                          borderColor: "#12A1A3 ",
                          color: "white",
                        }}
                      >
                        Next
                      </Button>
                    );
                  }
                  return originalElement;
                },
              }}
              style={{ marginTop: 16 }}
              scroll={{ x: "max-content" }}
            />
          </div>
        </div>
      </Spin>

      <EditBranchModal
        isVisible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onSuccess={handleSuccess}
        initialValues={currentBranch}
        companyId={currentBranch?.id}
      />
    </>
  );
};

export default ViewBranchList;
