import { Avatar, Button, Input, message, Select, Spin, Upload } from "antd";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import CountUp from "react-countup";
import ImgCrop from "antd-img-crop";
import { Editor } from "@tinymce/tinymce-react";
import {
  UserOutlined,
  MailOutlined,
  GlobalOutlined,
  PhoneOutlined,
  RiseOutlined,
  UploadOutlined,
} from "@ant-design/icons";

import { useForm, Controller } from "react-hook-form";

import {
  addCompanyDetails,
  uploadCardCoverPic,
  uploadCompanyLogo,
} from "../../../services/apiServices";
import { handleAuthenticationError } from "../../../utils/authHelpers";
import { CompanyContext } from "../../../contexts/CompanyContext";
import { editorApiKey } from "../../../utils/constants";
import EditSocialMediaModalBranch from "../ViewBranchList/EditSocialMediaModalBranch";
import "./ViewAddBranch.css";
import { formikValidationRules } from "../../../utils/validationRules";
import BreadcrumbComponent from "../../UI/bread-crumb";

const ViewAddBranch = () => {
  const navigate = useNavigate();
  const { companyIdCtx, setCompanyIdCtx } = useContext(CompanyContext);
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
    reset,
  } = useForm({ mode: "onChange" });

  const [isUpdatingCompany, setIsUpdatingCompany] = useState(false);
  const [companyLogo, setCompanyLogo] = useState("");
  const [fileList, setFileList] = useState([]);
  // const [coverPicture, setCoverPicture] = useState("");
  const [coverFileList, setCoverFileList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSocialMediaModalVisible, setIsSocialMediaModalVisible] =
    useState(false);
  const [productServices, setProductServices] = useState("");

  const { Option } = Select;

  const [coverPicture, setCoverPicture] = useState(null);
  const [logoPicture, setLogoPicture] = useState(null);

  // const onCoverChange = ({ file }) => {
  //   const reader = new FileReader();
  //   reader.onload = e => setCoverPicture(e.target.result);
  //   reader.readAsDataURL(file.originFileObj);
  // };

  const onLogoChange = ({ file }) => {
    const reader = new FileReader();
    reader.onload = (e) => setLogoPicture(e.target.result);
    reader.readAsDataURL(file.originFileObj);
  };

  const onFinishSubmit = async (values) => {
    setIsUpdatingCompany(true);
    try {
      let uploadedLogoPath =
        fileList.length > 0
          ? await handleCompanyLogoUpdate(fileList[0].originFileObj)
          : null;
      let uploadedCoverPath =
        coverFileList.length > 0
          ? await handleCoverPictureUpdate(coverFileList[0].originFileObj)
          : null;

      const addedValues = {
        ...values,
        company_logo: uploadedLogoPath,
        cover_pic: uploadedCoverPath,
        product_service: productServices,
      };

      const response = await addCompanyDetails(addedValues);
      if (response.status === 200) {
        message.success(response.data.message);
        reset();
      } else if (response.status === 401) {
        handleAuthenticationError(response.data.message, navigate);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("API request failed:", error);
      message.error("Failed to Update Company. Please try again later.");
    } finally {
      setIsUpdatingCompany(false);
    }
  };

  const handleCompanyLogoUpdate = async (imageFile) => {
    const response = await uploadCompanyLogo(imageFile);
    return response.status === 201
      ? response.data.data
      : message.error(response.data.message);
  };

  const handleCoverPictureUpdate = async (imageFile) => {
    const response = await uploadCardCoverPic(imageFile);
    return response.status === 201
      ? response.data.data
      : message.error(response.data.message);
  };

  const formatter = (value) => <CountUp end={value} separator="," />;

  const onChange = ({ fileList: newFileList }) => {
    setCompanyLogo(
      newFileList.length > 0
        ? URL.createObjectURL(newFileList[0].originFileObj)
        : null
    );
    setFileList(newFileList);
  };

  const onCoverChange = ({ fileList: newFileList }) => {
    setCoverPicture(
      newFileList.length > 0
        ? URL.createObjectURL(newFileList[0].originFileObj)
        : null
    );
    setCoverFileList(newFileList);
  };

  return (
    <>
      <Spin spinning={isUpdatingCompany} size="large">
        <BreadcrumbComponent
          breadcrumbItems={[
            { title: "Home" },
            { title: "Add New Branch", isActive: true },
          ]}
        />

        <h2 className="mx-4">Add New Branch</h2>
        <div className="viewCompanyDetailsContainer mx-4">
          <form onSubmit={handleSubmit(onFinishSubmit)}>
            <Row>
              {/* Cover Picture Section */}
              <Col
                lg={4}
                md={4}
                sm={6}
                xs={6}
                className="my-3 text-center mx-auto"
              >
                <ImgCrop rotationSlider aspectSlider showReset aspect={4 / 3}>
                  <Upload
                    accept="image/*"
                    onChange={onCoverChange}
                    showUploadList={false}
                    maxCount={1}
                    customRequest={({ file, onSuccess }) => onSuccess("ok")}
                  >
                    <div className="image-container">
                      {coverPicture ? (
                        <Avatar
                          src={coverPicture}
                          alt="Cover Picture"
                          shape="square"
                          className="avatar-contain"
                        />
                      ) : (
                        <div
                          style={{
                            cursor: "pointer",
                          }}
                          className="border border-2 p-2 rounded-4 d-flex align-items-center justify-content-center"
                        >
                          <UploadOutlined
                            style={{
                              fontSize: "24px",
                              marginRight: "8px",
                              cursor: "pointer",
                            }}
                          />
                          Upload Cover Picture
                        </div>
                      )}
                    </div>
                  </Upload>
                </ImgCrop>
              </Col>

              {/* Company Logo Section */}
              <Col
                lg={4}
                md={4}
                sm={6}
                xs={6}
                className="my-3 text-center mx-auto"
              >
                <Upload
                  accept="image/*"
                  onChange={onChange}
                  showUploadList={false}
                  fileList={fileList}
                  maxCount={1}
                  customRequest={({ file, onSuccess }) => onSuccess("ok")}
                >
                  <div className="image-container">
                    {companyLogo ? (
                      <Avatar
                        src={companyLogo}
                        alt="Company Logo"
                        shape="square"
                        className="avatar-contain"
                      />
                    ) : (
                      <div
                        style={{
                          cursor: "pointer",
                        }}
                        className="border border-2 p-2 rounded-4 d-flex align-items-center justify-content-center"
                      >
                        <UploadOutlined
                          style={{
                            fontSize: "24px",
                            marginRight: "8px",
                            cursor: "pointer",
                          }}
                        />
                        Upload Company Logo
                      </div>
                    )}
                  </div>
                </Upload>
              </Col>
            </Row>

            <Row className="mt-5">
              <Col lg={6} md={6} sm={6} xs={12} className="my-3 ">
                <Controller
                  name="company_name"
                  control={control}
                  rules={formikValidationRules.company_name}
                  render={({ field }) => (
                    <div className="ant-form-item">
                      <label className="ant-form-item-label fw-bold">
                        Entity Name
                      </label>

                      <Input
                        size="large"
                        placeholder="Enter Company Name"
                        {...field}
                        prefix={<UserOutlined className="mx-1" />}
                      />
                      {errors.company_name && (
                        <span className="ant-form-item-explain text-danger">
                          {errors.company_name.message}
                        </span>
                      )}
                    </div>
                  )}
                />
              </Col>
              <Col lg={6} md={6} sm={6} xs={12} className="my-3">
                <Controller
                  name="company_email"
                  control={control}
                  rules={formikValidationRules.email}
                  render={({ field }) => (
                    <div className="ant-form-item">
                      <label className="ant-form-item-label fw-bold">
                        Company Email
                      </label>
                      <Input
                        size="large"
                        placeholder="Enter Company Email"
                        {...field}
                        prefix={<MailOutlined className="mx-1" />}
                      />
                      {errors.company_email && (
                        <span className="ant-form-item-explain text-danger">
                          {errors.company_email.message}
                        </span>
                      )}
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={6} xs={12} className="my-3">
                <Controller
                  name="description"
                  control={control}
                  render={({ field }) => (
                    <div className="ant-form-item">
                      <label className="ant-form-item-label fw-bold">
                        Description
                      </label>
                      <Input.TextArea
                        size="large"
                        placeholder="Enter Description"
                        {...field}
                        prefix={<UserOutlined className="mx-1" />}
                      />
                    </div>
                  )}
                />
              </Col>
              <Col lg={6} md={6} sm={6} xs={12} className="my-3">
                <Controller
                  name="company_address"
                  control={control}
                  rules={formikValidationRules.company_address}
                  render={({ field }) => (
                    <div className="ant-form-item">
                      <label className="ant-form-item-label fw-bold">
                        Office Address
                      </label>
                      <Input.TextArea
                        size="large"
                        placeholder="Enter Company Address"
                        {...field}
                        prefix={<UserOutlined className="mx-1" />}
                      />
                      {errors.company_address && (
                        <span className="ant-form-item-explain text-danger">
                          {errors.company_address.message}
                        </span>
                      )}
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={6} sm={6} xs={12} className="my-3">
                <Controller
                  name="company_contact_number"
                  control={control}
                  rules={formikValidationRules.company_contact_number}
                  render={({ field }) => (
                    <div className="ant-form-item">
                      <label className="ant-form-item-label fw-bold">
                        Office Contact
                      </label>
                      <Input
                        size="large"
                        placeholder="Enter Office Contact"
                        {...field}
                        prefix={<PhoneOutlined className="mx-1" />}
                      />
                      {errors.company_contact_number && (
                        <span className="ant-form-item-explain text-danger">
                          {errors.company_contact_number.message}
                        </span>
                      )}
                    </div>
                  )}
                />
              </Col>
              <Col lg={6} md={6} sm={6} xs={12} className="my-3">
                <Controller
                  name="company_website"
                  control={control}
                  render={({ field }) => (
                    <div className="ant-form-item">
                      <label className="ant-form-item-label fw-bold">
                        Company Website
                      </label>
                      <Input
                        size="large"
                        placeholder="Enter Company Website"
                        {...field}
                        prefix={<GlobalOutlined className="mx-1" />}
                      />
                    </div>
                  )}
                />
              </Col>
            </Row>
            <Row>
              {/* <Col lg={6} md={6} sm={6} xs={12} className="my-3">
                <Controller
                  name=" Update location"
                  control={control}
                  render={({ field }) => (
                    <div className="ant-form-item">
                      <label className="ant-form-item-label fw-bold">
                        Update Location
                      </label>
                      <Input
                        size="large"
                        placeholder="Enter Google Map Location Link"
                        {...field}
                        prefix={<UserOutlined />}
                      />
                      {errors.location && (
                        <span className="ant-form-item-explain text-danger">
                          {errors.location.message}
                        </span>
                      )}
                    </div>
                  )}
                />
              </Col> */}

              {/* ****************************** */}
              {/* <Col lg={12} md={6} sm={6} xs={12} className="my-3">
                <Controller
                  name="update_location"
                  control={control}
                  render={({ field }) => (
                    <div className="ant-form-item">
                      <label className="ant-form-item-label fw-bold">
                        Update Location
                      </label>
                      <Select
                        {...field}
                        prefix={<RiseOutlined className="mx-1" />}
                        size="large"
                        placeholder="Select a Location"
                        style={{ width: "100%" }}
                      >
                        <Option value="location1">Location 1</Option>
                        <Option value="location2">Location 2</Option>
                        <Option value="location3">Location 3</Option>
                      </Select>
                      {errors.update_location && (
                        <span className="ant-form-item-explain text-danger">
                          {errors.update_location.message}
                        </span>
                      )}
                    </div>
                  )}
                />
              </Col> */}

              <Col lg={12} md={6} sm={6} xs={12} className="my-3">
                <Controller
                  name="update_location"
                  control={control}
                  rules={{
                    required: "Please enter a valid location URL.",
                    pattern: {
                      value:
                        /^(https?:\/\/)?([\w\-]+\.)+[\w\-]+(\/[\w\-]*)*\/?$/,
                      message: "Please enter a valid URL.",
                    },
                  }}
                  render={({ field }) => (
                    <div className="ant-form-item">
                      <label className="ant-form-item-label fw-bold">
                        Update Location
                      </label>
                      <Input
                        {...field}
                        prefix={<RiseOutlined className="mx-1" />}
                        size="large"
                        placeholder="Enter a location URL"
                        style={{ width: "100%" }}
                      />
                      {errors.update_location && (
                        <span className="ant-form-item-explain text-danger">
                          {errors.update_location.message}
                        </span>
                      )}
                    </div>
                  )}
                />
              </Col>

              {/* ********************* */}
            </Row>
            <Row>
              <Col lg={12} md={12} sm={12} xs={12} className="my-3">
                <label className="fw-bold my-1 w-100">Product / Services</label>
                <Editor
                  apiKey={editorApiKey}
                  value={productServices}
                  init={{
                    height: 300,
                    menubar: false,
                    placeholder: "Enter Product & Services",
                    plugins:
                      "anchor autolink charmap codesample emoticons image link searchreplace table visualblocks wordcount lists fontsize fontfamily ltr",
                    toolbar:
                      "undo redo | fontfamily fontsize | bold italic underline | image media | align lineheight | numlist bullist indent outdent | emoticons ",
                    images_default_resizing: "scale",
                    images_resizing: true,
                    file_picker_types: "image",
                    file_picker_callback: function (callback, value, meta) {
                      if (meta.filetype === "image") {
                        var input = document.createElement("input");
                        input.setAttribute("type", "file");
                        input.setAttribute("accept", "image/*");

                        input.onchange = function () {
                          var file = this.files[0];
                          var reader = new FileReader();

                          reader.onload = function (e) {
                            callback(e.target.result, {
                              alt: file.name,
                            });
                          };

                          reader.readAsDataURL(file);
                        };

                        input.click();
                      }
                    },
                    media_live_embeds: true,
                    media_embeds: true,
                    content_css: "tinymce-5",
                  }}
                  onEditorChange={(content) => setProductServices(content)}
                />
              </Col>
            </Row>
            <Row className="text-center">
              <Col lg={12} className="my-3">
                {/* <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  disabled={!isValid || isUpdatingCompany}
                >
                  {isUpdatingCompany ? "Saving Changes..." : "Save Changes"}
                </Button> */}
                <Button
                  size="large"
                  type="primary"
                  htmlType="submit"
                  disabled={!isValid || isUpdatingCompany}
                  style={{
                    backgroundColor: "#4D81A3",
                    // borderColor: "#12A1A3",
                    color: "white",
                  }}
                >
                  {isUpdatingCompany ? "Saving Changes..." : "Save Changes"}
                </Button>
              </Col>
            </Row>
          </form>
        </div>
      </Spin>

      <EditSocialMediaModalBranch
        isModalVisible={isSocialMediaModalVisible}
        setIsModalVisible={setIsSocialMediaModalVisible}
      />
    </>
  );
};

export default ViewAddBranch;
