// ProductBio.js
import React from "react";
import { Spin } from "antd";

const ProductBio = ({ cardDetails, isFetchingCard }) => (
  <div className="w-100">
    {!isFetchingCard ? (
      <>
        {cardDetails?.bio && (
          <div className="my-2">
            <div
              className="html_text_render"
              dangerouslySetInnerHTML={{ __html: cardDetails.bio }}
            />
          </div>
        )}
        {cardDetails?.product_service && (
          <div className="my-2">
            <div
              className="html_text_render"
              dangerouslySetInnerHTML={{
                __html: cardDetails.product_service,
              }}
            />
          </div>
        )}
      </>
    ) : (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ height: "25vh" }}
      >
        <Spin spinning={isFetchingCard} size="large" />
      </div>
    )}
  </div>
);

export default ProductBio;
