import React, { useState } from "react";
import { Form, Input, Button, message } from "antd";
import { LockOutlined } from "@ant-design/icons";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Image } from "react-bootstrap";

import "./ResetPasswordPage.css";
import { AppBackgroundImage, AppLogoLight } from "../../../utils/constants";
import { resetPassword } from "../../../services/apiServices";
import {
  MidinLogoImage,
  MidinSocialIcons,
} from "../../../components/UI/auth-screens";
import { validationRules } from "../../../utils/validationRules";

function ResetPasswordPage() {
  const navigate = useNavigate();
  const { resetToken } = useParams();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    setLoading(true);
    try {
      const { password } = values;
      const response = await resetPassword({ password, resetToken });
      if (response && response.status === 200) {
        message.success(response.data.message);
        navigate("/");
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("API request failed:", error);
      message.error("Failed to reset password. Please try again later.");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className="container-fluid d-flex flex-column"
        style={{ height: "100vh" }}
      >
        <div className="row flex-grow-1">
          <div className="col-md-7 col-sm-12 p-0 position-relative">
            <Image
              src={AppBackgroundImage}
              alt="Background"
              className="bgImg"
            />
            <div className="position-absolute top-50 start-0 translate-middle-y text-white mb-5 p-4 ">
              <h2 className="textsize">
                <b>Tap, Connect, Cultivate</b>
                <br />
                Where Business Networking Blossoms
              </h2>
            </div>
            <MidinLogoImage />
            <MidinSocialIcons />
          </div>
          <div
            className="col-sm-12 col-md-5  d-flex align-items-center justify-content-center"
            style={{ backgroundColor: "#304244" }}
          >
            <div
              className="card border-0  w-50"
              style={{ backgroundColor: "#304244" }}
            >
              <NavLink to={"/"}>
                <Image
                  src={AppLogoLight}
                  className="mx-auto mt-4 mb-3"
                  alt="LogoImgSize"
                  style={{ width: "200px" }}
                />
              </NavLink>

              <Form name="basic" onFinish={onFinish}>
                <Form.Item
                  name="password"
                  hasFeedback
                  rules={validationRules.new_password}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="New Password"
                    size="large"
                  />
                </Form.Item>

                <Form.Item
                  name="confirmPassword"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "Please Confirm Password !",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error(
                            "New Password & Confirm Password Must Be Same !"
                          )
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password
                    prefix={<LockOutlined className="site-form-item-icon" />}
                    placeholder="Confirm Password"
                    size="large"
                  />
                </Form.Item>

                <Form.Item>
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="btn-reset-pass w-100"
                    loading={loading}
                    size="large"
                  >
                    Reset Password
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ResetPasswordPage;
