import React from "react";
import { Image } from "react-bootstrap";
import PropTypes from "prop-types";

const CardQr = React.memo(({ cardDetails }) => {
  // Guard clause for invalid data
  if (!cardDetails?.qr_url) {
    return null;
  }

  return (
    <div className="m-0 p-0">
      <Image
        height={"100%"}
        width={"100%"}
        src={cardDetails.qr_url}
        alt="Card QR Code"
        loading="lazy"
      />
    </div>
  );
});

// PropTypes for type-checking in development
CardQr.propTypes = {
  cardDetails: PropTypes.shape({
    qr_url: PropTypes.string,
  }),
};

export default CardQr;
