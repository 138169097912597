import {
  makeDynamicFormDataApiRequest,
  makeFormDataApiRequest,
  makeJsonApiRequest,
} from "./apiRequests";

const api_base_url = "https://midin.app/api/v1/companyAdmin/";
const card_base_url = "https://midin.app/api/v1/";
// const api_base_url = "http://localhost:3007/api/v1/companyAdmin/";
// const card_base_url = "http://localhost:3007/api/v1/";

// Function to set authentication token in local storage
const setAuthToken = (token) => {
  if (token) {
    localStorage.setItem("user-token", token);
    localStorage.setItem("authenticated", true);
    localStorage.setItem("previousTab", 1);
  } else {
    localStorage.removeItem("user-token");
    localStorage.setItem("authenticated", false);
    localStorage.removeItem("previousTab");
  }
};

/*----- Auth Section -----*/

// login user
export const loginUser = async (loginCredentials) => {
  const response = await makeJsonApiRequest(
    "post",
    `${api_base_url}loginCompanyAdmin`,
    loginCredentials
  );
  if (response && response.status === 200) {
    setAuthToken(response.data.data.token);
  }
  return response;
};

// logout user
export const logoutUser = async () => {
  try {
    setAuthToken(null);
    localStorage.removeItem("user-token");
    localStorage.removeItem("authenticated");
    localStorage.removeItem("previousTab");
    localStorage.removeItem("companyId");
    return true;
  } catch (error) {
    console.error("Error logging out:", error);
    return false;
  }
};

// view profile
export const fetchViewProfile = async () => {
  const response = await makeJsonApiRequest(
    "get",
    `${api_base_url}showCAProfile`
  );
  return response;
};

// change password
export const changePassword = async (passwordCredentials) => {
  const response = await makeJsonApiRequest(
    "put",
    `${api_base_url}changePassword`,
    passwordCredentials
  );
  return response;
};

// edit profile
export const editProfile = async (updatedData) => {
  const response = await makeJsonApiRequest(
    "put",
    `${api_base_url}editProfile`,
    updatedData
  );
  return response;
};

// upload avatar
export const uploadAvatar = async (avatarData) => {
  const response = await makeFormDataApiRequest(
    "post",
    `${api_base_url}uploadAvatar`,
    avatarData
  );
  return response;
};

// forget password
export const forgetPassword = async (email) => {
  const response = await makeJsonApiRequest(
    "post",
    `${api_base_url}forgetPassword`,
    email
  );
  return response;
};

// reset password
export const resetPassword = async (passwordCredentials) => {
  const response = await makeJsonApiRequest(
    "put",
    `${api_base_url}resetPassword`,
    passwordCredentials
  );
  return response;
};

/*----- Card Section -----*/

// card lists
export const fetchCardsList = async () => {
  const response = await makeJsonApiRequest("get", `${api_base_url}cardLists`);
  return response;
};

// create business card
export const createBusinessCard = async (cardDetails) => {
  const response = await makeJsonApiRequest(
    "post",
    `${api_base_url}createCard`,
    cardDetails
  );
  return response;
};

// crate multiple business card
export const createMultipleBusinessCard = async (fileData) => {
  const response = await makeDynamicFormDataApiRequest(
    "post",
    `${api_base_url}uploadCreateCardFile`,
    fileData
  );
  return response;
};

// upload card profile pic
export const uploadCardProfilePic = async (imageData) => {
  const response = await makeFormDataApiRequest(
    "post",
    `${api_base_url}uploadCardProfilePicture`,
    imageData
  );
  return response;
};

// upload cover image
export const uploadCardCoverPic = async (imageData) => {
  const response = await makeFormDataApiRequest(
    "post",
    `${api_base_url}uploadCardCoverPicture`,
    imageData
  );
  return response;
};

// activate card for QR
export const activateCardforQRCode = async (cardID) => {
  const response = await makeJsonApiRequest(
    "put",
    `${api_base_url}activateSingleCardForQr?card_id=${cardID}`,
    {}
  );
  return response;
};

// activate multiple card for QR
export const activateMultipleCardforQRCode = async (cardIDs) => {
  const response = await makeJsonApiRequest(
    "put",
    `${api_base_url}activateMultipleCardsForQR`,
    cardIDs
  );
  return response;
};

// deactivate card
export const deactivateCard = async (cardID, status) => {
  const response = await makeJsonApiRequest(
    "put",
    `${api_base_url}deactivateCard?card_id=${cardID}&status=${status}`,
    {}
  );
  return response;
};

// delete card
export const deleteCard = async (cardID) => {
  const response = await makeJsonApiRequest(
    "put",
    `${api_base_url}deleteCard?card_id=${cardID}`,
    {}
  );
  return response;
};

// view card
export const fetchViewDigitalCard = async (cardID) => {
  const response = await makeJsonApiRequest(
    "get",
    `${api_base_url}cardDetailsForCA?card_id=${cardID}`
  );
  return response;
};

// edit card
export const editCardDetails = async (updatedData) => {
  const response = await makeJsonApiRequest(
    "put",
    `${api_base_url}editCard`,
    updatedData
  );
  return response;
};

// view card for all
export const fetchViewDigitalCardAll = async (queries) => {
  const response = await makeJsonApiRequest(
    "get",
    `${card_base_url}card?comp_name=${queries.companyName}&card_ref=${queries.cardReference}`
  );
  return response;
};

// get vcf card of card
export const getVCFCardforDigitalCard = async (vcfCardID) => {
  const response = await makeJsonApiRequest(
    "get",
    `${card_base_url}vcf?card_id=${vcfCardID}`
  );
  return response;
};

// cards url lists
export const fetchCardsUrlList = async () => {
  const response = await makeJsonApiRequest("get", `${api_base_url}qrCodeList`);
  return response;
};

// export card details
export const exportCardsDetailsFile = async () => {
  const response = await makeJsonApiRequest(
    "get",
    `${api_base_url}exportCardDetail`
  );
  return response;
};

/*----- Company Section -----*/

// company details
export const fetchCompanyDetails = async () => {
  const response = await makeJsonApiRequest(
    "get",
    `${api_base_url}companyDetails`
  );
  return response;
};

// upload company logo
export const uploadCompanyLogo = async (imageData) => {
  const response = await makeFormDataApiRequest(
    "post",
    `${api_base_url}uploadCompanyLogo`,
    imageData
  );
  return response;
};

// remove company logo
export const removeCompanyLogo = async (company_id) => {
  const response = await makeJsonApiRequest(
    "post",
    `${api_base_url}removeCompanyLogo`,
    {
      company_id,
    }
  );
  return response;
};

// remove company picture
export const removeCompanyPicture = async (company_id) => {
  const response = await makeJsonApiRequest(
    "post",
    `${api_base_url}removeCoverPic`,
    {
      company_id,
    }
  );
  return response;
};

// edit company details
export const editCompanyDetails = async (updatedData) => {
  const response = await makeJsonApiRequest(
    "post",
    `${api_base_url}editCompanyDetails`,
    updatedData
  );
  return response;
};

// social media details
export const fetchSocialMediaDetails = async (companyID) => {
  const response = await makeJsonApiRequest(
    "get",
    `${api_base_url}socialMediaDetails?company_id=${companyID}`
  );
  return response;
};

// edit social media details
export const editSocialMediaDetails = async (updatedData) => {
  const response = await makeJsonApiRequest(
    "put",
    `${api_base_url}editSocialMedia`,
    updatedData
  );
  return response;
};

// add company details
export const addCompanyDetails = async (companyData) => {
  const response = await makeJsonApiRequest(
    "post",
    `${api_base_url}addCompanyDetails`,
    companyData
  );
  return response;
};

// company address list
export const getCompanyDetailsLists = async (companyData) => {
  const response = await makeJsonApiRequest(
    "get",
    `${api_base_url}getCompanyDetailsLists`
  );
  return response;
};

// set default address
export const setDefaultAddress = async (companyId) => {
  const response = await makeJsonApiRequest(
    "put",
    `${api_base_url}setDefaultAddress`,
    { company_id: companyId }
  );
  return response;
};

// delete company details / address
export const deleteCompanyDetails = async (companyId) => {
  const response = await makeJsonApiRequest(
    "put",
    `${api_base_url}deleteCompanyDetails`,
    { company_id: companyId }
  );
  return response;
};

// get extra company details
export const getExtraCompanyDetails = async (companyId) => {
  const response = await makeJsonApiRequest(
    "get",
    `${api_base_url}extraCompanyDetails?company_id=${companyId}`
  );
  return response;
};

// edit extra company details
export const editExtraCompanyDetails = async (payload) => {
  const response = await makeJsonApiRequest(
    "put",
    `${api_base_url}editExtraCompanyDetails`,
    payload
  );
  return response;
};
