// SocialMediaSection.js
import React from "react";
import { ScrollMenu } from "react-horizontal-scrolling-menu";
import { SocialMediaButton } from "../../components/UI/view-digital-card";

const SocialMedia = ({ cardDetails, socialPlatforms, openSocialLink }) => (
  <div>
    {socialPlatforms.some((platform) => cardDetails[platform]) && (
      <div
        className="rounded-4 mx-3"
        style={{
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        <ScrollMenu scrollContainerClassName="no-scrollbar p-2">
          {socialPlatforms.map(
            (platform) =>
              cardDetails[platform] && (
                <div
                  key={platform}
                  className="d-flex flex-column align-items-center justify-content-center"
                  style={{ minWidth: "100px" }}
                >
                  <div
                    className="d-flex align-items-center justify-content-center rounded-circle bg-light"
                    style={{ width: "50px", height: "50px" }}
                  >
                    <SocialMediaButton
                      platform={platform}
                      link={cardDetails[platform]}
                      onClick={openSocialLink}
                    />
                  </div>
                  <small
                    className="text-center text-secondary"
                    style={{ maxWidth: "80px", wordBreak: "break-word" }}
                  >
                    {platform.replace(/[_-]/g, " ").toUpperCase()}
                  </small>
                </div>
              )
          )}
        </ScrollMenu>
      </div>
    )}
  </div>
);

export default SocialMedia;
