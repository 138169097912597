import React, { useState } from "react";
import { Form, Input, Button, message, Modal } from "antd";
import "./ViewChangePassword.css";
import { Col, Row } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../../services/apiServices";
import { ExclamationCircleFilled } from "@ant-design/icons";
import { handleAuthenticationError } from "../../../utils/authHelpers";
import MidinFooter from "../../MidinFooter/MidinFooter";
import { validationRules } from "../../../utils/validationRules";
import { LockOutlined } from "@ant-design/icons";
import BreadcrumbComponent from "../../UI/bread-crumb";

const { Item } = Form;

function ViewChangePassword() {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const onFinishSubmit = async (values) => {
    try {
      const response = await changePassword(values);
      if (response && response.status === 200) {
        message.success(response.data.message);
        form.resetFields();
      } else if (response.status === 401) {
        handleAuthenticationError(response.data.message, navigate);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("API request failed:", error);
      message.error("Failed to Change Password. Please try again later.");
    }
  };

  const confirmChangePassword = () => {
    setIsModalVisible(false);
    form.submit();
  };

  return (
    <>
      <div>
        <BreadcrumbComponent
          breadcrumbItems={[
            { title: "Home" },
            { title: "Change Password", isActive: true },
          ]}
        />

        <h2 className="mx-4"> Change Password </h2>
        <p className="mx-4">
          Change your password to keep your account safe and secure
        </p>
        <div className="viewChangePasswordContainer">
          <Row>
            <Col lg={8} md={12} sm={12}>
              <Form
                form={form}
                layout="vertical"
                onFinish={onFinishSubmit}
                name="changePasswordForm"
              >
                <Col>
                  <label className="fw-bold mb-1 ">Old Password</label>
                  <Item
                    name="old_password"
                    rules={validationRules.old_password}
                    hasFeedback
                  >
                    <Input.Password
                      placeholder="Enter Old Password"
                      size="large"
                      prefix={
                        <LockOutlined
                          style={{ color: "#595959" }}
                          className="mx-1"
                        />
                      }
                    />
                  </Item>
                </Col>
                <Col>
                  <label className="fw-bold mb-1">New Password</label>
                  <Item
                    name="new_password"
                    rules={validationRules.new_password}
                    hasFeedback
                  >
                    <Input.Password
                      placeholder="Enter New Password"
                      size="large"
                      prefix={
                        <LockOutlined
                          style={{ color: "#595959" }}
                          className="mx-1"
                        />
                      }
                    />
                  </Item>
                </Col>
                <Col>
                  <label className="fw-bold mb-1">Confirm Password</label>
                  <Item
                    name="confirm_password"
                    dependencies={["new_password"]}
                    hasFeedback
                    rules={validationRules.confirm_password}
                  >
                    <Input.Password
                      placeholder="Confirm Your New Password"
                      size="large"
                      prefix={
                        <LockOutlined
                          style={{ color: "#595959" }}
                          className="mx-1"
                        />
                      }
                    />
                  </Item>
                </Col>
                <Row>
                  <Col lg={4} md={4} sm={3}></Col>
                  <Col lg={4} md={4} sm={6}>
                    <Item>
                      <Button
                        type="primary"
                        className="w-10"
                        size="large"
                        onClick={showModal}
                      >
                        Change Password
                      </Button>
                    </Item>
                  </Col>
                  <Col lg={4} md={4} sm={3}></Col>
                </Row>
              </Form>
            </Col>
            <Col lg={4} md={4} sm={3}></Col>
          </Row>
        </div>

        <MidinFooter />

        <Modal
          centered
          title={
            <span className="fw-bold fs-5">
              <ExclamationCircleFilled className="mx-2 text-warning text-center" />
              Do you want to Save Changes?
            </span>
          }
          open={isModalVisible}
          onCancel={handleCancel}
          footer={[
            <div className="text-center">
              <Button
                key="cancel"
                onClick={handleCancel}
                size="large"
                danger
                className="me-2"
              >
                Cancel
              </Button>

              <Button
                key="confirm"
                type="primary"
                onClick={confirmChangePassword}
                size="large"
                className="ms-2"
              >
                Confirm
              </Button>
            </div>,
          ]}
        ></Modal>
      </div>
    </>
  );
}

export default ViewChangePassword;
