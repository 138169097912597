import {
  Avatar,
  Button,
  Form,
  Input,
  message,
  Spin,
  Upload,
  Modal,
} from "antd";
import React, { useCallback, useContext, useEffect, useState } from "react";
import "./ViewCompanyDetails.css";
import { NavLink, useNavigate } from "react-router-dom";
import {
  editCompanyDetails,
  fetchCompanyDetails,
  removeCompanyLogo,
  removeCompanyPicture,
  uploadCardCoverPic,
  uploadCompanyLogo,
} from "../../../services/apiServices";
import { Col, Row } from "react-bootstrap";
import {
  ExclamationCircleFilled,
  RightOutlined,
  PlusOutlined,
  UploadOutlined,
} from "@ant-design/icons";
import CountUp from "react-countup";

import ImgCrop from "antd-img-crop";

import { Editor } from "@tinymce/tinymce-react";
import { handleAuthenticationError } from "../../../utils/authHelpers";
import { CompanyContext } from "../../../contexts/CompanyContext";
import { editorApiKey } from "../../../utils/constants";
import MidinFooter from "../../MidinFooter/MidinFooter";
import EditSocialMediaModal from "./EditSocialMediaModal";
import { validationRules } from "../../../utils/validationRules";
import { MdArrowOutward } from "react-icons/md";
import { StatisticCard } from "../../UI/company-details";
import BreadcrumbComponent from "../../UI/bread-crumb";

const { Item } = Form;

function ViewCompanyDetails() {
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { companyIdCtx, setCompanyIdCtx } = useContext(CompanyContext);

  const [isFetchingCompany, setIsFetchingCompany] = useState(false);
  const [isUpdatingCompany, setIsUpdatingCompany] = useState(false);
  const [companyData, setCompanyData] = useState({});
  const [productServices, setProductServices] = useState("");
  const [companyLogo, setCompanyLogo] = useState("");
  const [fileList, setFileList] = useState([]);
  const [coverPicture, setCoverPicture] = useState("");
  const [coverFileList, setCoverFileList] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [isSocialMediaModalVisible, setIsSocialMediaModalVisible] =
    useState(false);

  const onFinishSubmit = async (values) => {
    setIsUpdatingCompany(true);
    try {
      let uploadedLogoPath = companyData.company_logo;
      let uploadedCoverPath = companyData.cover_pic;

      if (fileList && fileList.length > 0 && fileList[0]?.originFileObj) {
        uploadedLogoPath = await handleCompanyLogoUpdate(
          fileList[0]?.originFileObj
        );
      }

      if (
        coverFileList &&
        coverFileList.length > 0 &&
        coverFileList[0]?.originFileObj
      ) {
        uploadedCoverPath = await handleCoverPictureUpdate(
          coverFileList[0]?.originFileObj
        );
      }

      const updatedValues = {
        ...values,
        company_id: companyData.id,
        company_logo: uploadedLogoPath || companyData.company_logo,
        cover_pic: uploadedCoverPath || companyData.cover_pic,
        product_service: productServices,
      };

      const response = await editCompanyDetails(updatedValues);
      if (response && response.status === 200) {
        message.success(response.data.message);
        fetchViewCompanyData();
      } else if (response.status === 401) {
        handleAuthenticationError(response.data.message, navigate);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("API request failed:", error);
      message.error("Failed to Update Company. Please try again later.");
      setIsUpdatingCompany(false);
    } finally {
      setIsUpdatingCompany(false);
    }
  };

  const fetchViewCompanyData = useCallback(async () => {
    try {
      setIsFetchingCompany(true);
      const response = await fetchCompanyDetails();
      if (response && response.status === 200) {
        setCompanyData(response.data.data[0]);
        setCompanyLogo(response.data.data[0].company_logo);
        setCoverPicture(response.data.data[0].cover_pic);
        setProductServices(response.data.data[0].product_service);
        setCompanyIdCtx(response.data.data[0].id);

        form.setFieldsValue(response.data.data[0]);
      } else if (response.status === 401) {
        handleAuthenticationError(response.data.message, navigate);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("API request failed:", error);
      message.error("Failed to Load Details. Please try again later.");
      setIsFetchingCompany(false);
    } finally {
      setIsFetchingCompany(false);
    }
  }, [navigate, form, setCompanyIdCtx]);

  const handleCompanyLogoUpdate = async (imageFile) => {
    if (fileList.length > 0) {
      const file = fileList[0].originFileObj;

      const response = await uploadCompanyLogo(imageFile);
      if (response.status === 201) {
        let imagePath = response.data.data;
        return imagePath;
      } else {
        message.error(response.data.message);
        return;
      }
    } else {
      return companyData.company_logo || "";
    }
  };

  const handleCoverPictureUpdate = async (imageFile) => {
    if (coverFileList.length > 0) {
      const response = await uploadCardCoverPic(imageFile);
      if (response.status === 201) {
        let imagePath = response.data.data;
        return imagePath;
      } else {
        message.error(response.data.message);
        return;
      }
    } else {
      return companyData.cover_pic || "";
    }
  };

  const handleRemoveCompanyLogo = async () => {
    try {
      // Call the API to delete the company logo
      const response = await removeCompanyLogo(companyData.id);
      if (response.status === 200) {
        setCompanyLogo(null);
        fetchCompanyDetails();
        message.success(response.data.message);
      } else {
        message.error(
          response.data.message || "Failed to remove company logo."
        );
      }
    } catch (error) {
      console.error("Error removing company logo:", error);
      message.error("Failed to remove company logo. Please try again.");
    }
  };

  const handleRemoveCoverPicture = async () => {
    try {
      // Call the API to delete the cover picture
      const response = await removeCompanyPicture(companyData.id); // Replace with delete API call
      if (response.status === 200) {
        setCoverPicture(null);
        fetchCompanyDetails();
        message.success(response.data.message);
      } else {
        message.error(
          response.data.message || "Failed to remove cover picture."
        );
      }
    } catch (error) {
      console.error("Error removing cover picture:", error);
      message.error("Failed to remove cover picture. Please try again.");
    }
  };

  useEffect(() => {
    fetchViewCompanyData();
  }, [navigate, fetchViewCompanyData]);

  const formatter = (value) => <CountUp end={value} separator="," />;

  const onChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const newAvatarPreview = URL.createObjectURL(
        newFileList[0].originFileObj
      );
      setCompanyLogo(newAvatarPreview);
    } else {
      setCompanyLogo("");
    }
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const onCoverChange = ({ fileList: newFileList }) => {
    if (newFileList.length > 0) {
      const newAvatarPreview = URL.createObjectURL(
        newFileList[0].originFileObj
      );
      setCoverPicture(newAvatarPreview);
    } else {
      setCoverPicture("");
    }
    setCoverFileList(newFileList);
  };

  const onCoverPreview = async (file) => {
    let src = file.url;
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }
    const image = new Image();
    image.src = src;
    const imgWindow = window.open(src);
    imgWindow?.document.write(image.outerHTML);
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const confirmSaveChanges = () => {
    setIsModalVisible(false);
    form.submit();
  };

  const showSocialMediaModal = () => {
    setIsSocialMediaModalVisible(true);
  };

  const handleSocialMediaModalCancel = () => {
    setIsSocialMediaModalVisible(false);
  };

  const handleSocialMediaModalSave = (details) => {
    setIsSocialMediaModalVisible(false);
  };

  const handleEditorChange = (content, editor) => {
    setProductServices(content);
  };

  return (
    <>
      <Spin spinning={isFetchingCompany} size="large">
        <div className="viewCompanyDetailsContainer">
          <Row>
            <Col lg={8} md={6} sm={12} xs={12}>
              <BreadcrumbComponent
                breadcrumbItems={[
                  { title: "Home" },
                  { title: "Company Details", isActive: true },
                ]}
              />

              <h2 className="mx-4">
                Hello, {companyData.contact_person_name || "User"}!
              </h2>
              <p className="mx-4">
                Welcome back! We're happy to have you with us.
              </p>
              <div className="mx-4">
                {/* <label className="fw-bold my-1 w-100"></label> */}
                <div className="mt-5">
                  <Row className="m-0 p-0">
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <label className="fw-bold my-1">Cover Picture</label>
                      <ImgCrop
                        rotationSlider
                        aspectSlider
                        showReset
                        aspect={4 / 3}
                      >
                        <Upload
                          accept="image/*"
                          onChange={onCoverChange}
                          onPreview={onCoverPreview}
                          showUploadList={false}
                          fileList={coverFileList}
                          maxCount={1}
                          customRequest={({ file, onSuccess }) => {
                            setTimeout(() => {
                              onSuccess("ok");
                            }, 0);
                          }}
                        >
                          <div className="image-container">
                            {coverPicture ? (
                              <Avatar
                                src={coverPicture}
                                alt="Cover Picture"
                                shape="square"
                                className="avatar-contain"
                              />
                            ) : (
                              <div
                                style={{
                                  cursor: "pointer",
                                }}
                                className="border border-2 p-2 rounded-4 d-flex align-items-center justify-content-center"
                              >
                                <UploadOutlined
                                  style={{
                                    fontSize: "24px",
                                    marginRight: "8px",
                                    cursor: "pointer",
                                  }}
                                />
                                Upload Cover Picture
                              </div>
                            )}
                          </div>
                        </Upload>
                      </ImgCrop>
                      {coverPicture && (
                        <div>
                          <Button
                            type="link"
                            danger
                            onClick={handleRemoveCoverPicture}
                          >
                            Remove
                          </Button>
                        </div>
                      )}
                    </Col>
                    <Col lg={6} md={6} sm={12} xs={12}>
                      <label className="fw-bold my-1">Company Logo</label>
                      <Upload
                        accept="image/*"
                        onChange={onChange}
                        onPreview={onPreview}
                        showUploadList={false}
                        fileList={fileList}
                        maxCount={1}
                        customRequest={({ file, onSuccess }) => {
                          setTimeout(() => {
                            onSuccess("ok");
                          }, 0);
                        }}
                      >
                        <div className="image-container">
                          {companyLogo ? (
                            <Avatar
                              src={companyLogo}
                              alt="Company Logo"
                              shape="square"
                              className="avatar-contain"
                            />
                          ) : (
                            <div
                              style={{
                                cursor: "pointer",
                              }}
                              className="border border-2 p-2 rounded-4 d-flex align-items-center justify-content-center"
                            >
                              <UploadOutlined
                                style={{
                                  fontSize: "24px",
                                  marginRight: "8px",
                                  cursor: "pointer",
                                }}
                              />
                              Upload Company Logo
                            </div>
                          )}
                        </div>
                      </Upload>
                      {companyLogo && (
                        <div>
                          <Button
                            type="link"
                            danger
                            onClick={handleRemoveCompanyLogo}
                          >
                            Remove
                          </Button>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={4} md={6} sm={12} xs={12} className="my-4 p-5">
              <Row className="g-4">
                <StatisticCard
                  title="Active Cards"
                  tagIcon={<MdArrowOutward className="fs-2" />}
                  background="linear-gradient(205.92deg, #CBE1DC -12.96%, #87D8F9 134.13%)"
                  value={companyData.used_cards}
                />
                <StatisticCard
                  title="Max Cards"
                  tagIcon={<PlusOutlined className="fs-2" />}
                  background="#E4FFE0"
                  value={companyData.max_cards}
                />
              </Row>{" "}
            </Col>
          </Row>

          <Form
            form={form}
            layout="vertical"
            onFinish={onFinishSubmit}
            name="editCompanyDetailForm"
          >
            <h2 className="mb-3">Company Details</h2>
            <Row>
              <Col lg={6} md={12} sm={6}>
                <label className="fw-bold my-1">Entity Name</label>
                <Item
                  name="company_name"
                  rules={validationRules.company_name}
                  hasFeedback
                >
                  <Input
                    size="large"
                    placeholder="Enter Your Entity Name"
                    defaultValue={companyData?.company_name || "N/A"}
                  />
                </Item>
              </Col>
              <Col lg={6} md={12} sm={6}>
                <label className="fw-bold my-1">Office Email</label>
                <Item
                  name="company_email"
                  rules={validationRules.email}
                  hasFeedback
                >
                  <Input
                    size="large"
                    placeholder="Enter Your Office Email"
                    defaultValue={companyData?.company_email || "N/A"}
                  />
                </Item>
              </Col>
              <Col lg={6} md={12} sm={6}>
                <label className="fw-bold my-1">Description</label>
                <Item name="description" hasFeedback>
                  <Input
                    size="large"
                    placeholder="Enter Company Description"
                    defaultValue={companyData?.description || "N/A"}
                  />
                </Item>
              </Col>
              <Col lg={6} md={12} sm={6}>
                <label className="fw-bold my-1">Office Address</label>
                <Item
                  name="company_address"
                  rules={validationRules.company_address}
                  hasFeedback
                >
                  <Input
                    size="large"
                    placeholder="Enter Your Office Address"
                    defaultValue={companyData?.company_address || "N/A"}
                  />
                </Item>
              </Col>

              <Col lg={6} md={12} sm={6}>
                <label className="fw-bold my-1">Office Contact</label>
                <Item
                  name="company_contact_number"
                  rules={validationRules.contact_number}
                  hasFeedback
                >
                  <Input
                    size="large"
                    placeholder="Enter Your Office Contact"
                    defaultValue={companyData.company_contact_number || "N/A"}
                  />
                </Item>
              </Col>

              <Col lg={6} md={12} sm={6}>
                <label className="fw-bold my-1">Website</label>
                <Item name="company_website" hasFeedback>
                  <Input
                    size="large"
                    placeholder="Enter Your Website"
                    defaultValue={companyData.company_website || "N/A"}
                  />
                </Item>
              </Col>

              <Col lg={6} md={12} sm={6}>
                <label className="fw-bold my-1">Contact Person Name</label>
                <Item
                  name="contact_person_name"
                  rules={validationRules.contact_person_name}
                  hasFeedback
                >
                  <Input
                    size="large"
                    disabled
                    placeholder="Enter Your Contact Person Name"
                    defaultValue={companyData.contact_person_name || "N/A"}
                  />
                </Item>
              </Col>
              <Col lg={6} md={12} sm={6}>
                <label className="fw-bold my-1">Contact Person Email</label>
                <Item
                  name="contact_person_email"
                  rules={validationRules.contact_person_email}
                  hasFeedback
                >
                  <Input
                    size="large"
                    disabled
                    placeholder="Enter Your Contact Person Email"
                    defaultValue={companyData.contact_person_email || "N/A"}
                  />
                </Item>
              </Col>
              <Col lg={6} md={12} sm={6}>
                <label className="fw-bold my-1">Update Location</label>
                <Item name="location" hasFeedback>
                  <Input
                    size="large"
                    placeholder="Enter Your Google Map Location Link"
                    defaultValue={companyData.location || "N/A"}
                  />
                </Item>
              </Col>
              <Col lg={6} md={12} sm={6}>
                <label className="fw-bold my-1">Current Location</label>
                <Form.Item name="location">
                  <NavLink to={companyData.location} target="_blank">
                    <Button
                      type="link"
                      title="Open Company Location"
                      className="fw-bold fs-6 ps-0 pe-0 text-wrap"
                    >
                      {companyData?.company_address}
                    </Button>
                  </NavLink>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col lg={12} md={12} sm={12} className="quill-editor mb-4">
                <label className="fw-bold my-1">Product and Services</label>
                <Editor
                  apiKey={editorApiKey}
                  initialValue={companyData.product_service}
                  init={{
                    placeholder: "Enter Company Product & Services Details",
                    plugins:
                      "anchor media autolink charmap codesample emoticons image link searchreplace table visualblocks wordcount lists fontsize fontfamily autoresize",
                    toolbar:
                      "undo redo | fontfamily fontsize | bold italic underline | image media | align lineheight | numlist bullist indent outdent | emoticons ",
                    images_default_resizing: "scale",
                    images_resizing: true,
                    file_picker_types: "image",
                    file_picker_callback: function (callback, value, meta) {
                      if (
                        meta.filetype === "image" ||
                        meta.filetype === "video"
                      ) {
                        var input = document.createElement("input");
                        input.setAttribute("type", "file");
                        input.setAttribute(
                          "accept",
                          meta.filetype === "image" ? "image/*" : "video/*"
                        );

                        // Trigger the file selection dialog when the input element changes
                        input.onchange = function () {
                          var file = this.files[0];
                          var reader = new FileReader();

                          reader.onload = function (e) {
                            // Pass the selected file back to the editor
                            callback(e.target.result, {
                              alt: file.name, // You can customize this if needed
                            });
                          };

                          reader.readAsDataURL(file);
                        };

                        // Click the input element to open the file selection dialog
                        input.click();
                      }
                    },
                    media_live_embeds: true,
                    media_embeds: true,
                    content_css: "tinymce-5",
                    height: "100%",
                  }}
                  onEditorChange={handleEditorChange}
                />
              </Col>
            </Row>

            <Row>
              <Col lg={3} md={6} sm={6} className="mx-0 justify-content-end">
                <Item>
                  <Button
                    type="primary"
                    className="w-100"
                    size="large"
                    // htmlType="submit"
                    loading={isUpdatingCompany}
                    onClick={showModal}
                  >
                    Save Changes
                  </Button>
                </Item>
              </Col>
              <Col lg={3} md={6} sm={6}>
                <Item>
                  <Button
                    className="w-100"
                    htmlType="button"
                    size="large"
                    type="primary"
                    onClick={showSocialMediaModal}
                  >
                    Edit Social Media
                  </Button>
                </Item>
              </Col>
            </Row>
          </Form>
        </div>
      </Spin>
      <MidinFooter />

      <Modal
        centered
        title={
          <span className="fw-bold fs-5">
            <ExclamationCircleFilled className="mx-2 text-warning" />
            Do you Want to Save Changes?
          </span>
        }
        open={isModalVisible}
        onCancel={handleCancel}
        footer={[
          <Button key="cancel" onClick={handleCancel} size="large" danger>
            Cancel
          </Button>,
          <Button
            key="confirm"
            type="primary"
            onClick={confirmSaveChanges}
            size="large"
          >
            Confirm
          </Button>,
        ]}
      ></Modal>

      <EditSocialMediaModal
        visible={isSocialMediaModalVisible}
        onCancel={handleSocialMediaModalCancel}
        onSave={handleSocialMediaModalSave}
        companyID={companyData?.id}
        companyIdCtx={companyIdCtx}
      />
    </>
  );
}

export default ViewCompanyDetails;
