import React from "react";
import { Card, Skeleton } from "antd";
import { Image } from "react-bootstrap";

const ProfileHeader = ({ cardDetails, isFetchingCard, imageUrl }) => {
  return (
    <div className="mt-0 p-0 position-relative">
      {/* Profile Header Card */}
      <Card
        className="m-0 p-0 "
        style={{
          background: cardDetails?.cover_pic
            ? `url(${cardDetails.cover_pic}) no-repeat center center/cover`
            : "#1c1c1c", // Default black background

          minHeight: "300px",
          color: "#fff",
          position: "relative",
        }}
      >
        {/* Cover Image Skeleton (Fallback for Loading State) */}
        {isFetchingCard && (
          <Skeleton.Avatar
            active
            shape="square"
            style={{
              width: "100%",
              height: "200px",
              borderRadius: "0.5rem",
            }}
          />
        )}
      </Card>

      <div
        className="position-absolute rounded-circle d-flex justify-content-center align-items-center w-100"
        style={{
          bottom: "-70px", // Floats outside the card
          height: "140px",
          overflow: "hidden",
          zIndex: 2,
        }}
      >
        {!isFetchingCard ? (
          imageUrl && (
            <Image
              src={imageUrl}
              alt="Profile"
              className="w-100 h-100 rounded-circle "
              style={{ objectFit: "cover", maxWidth: "140px" }}
            />
          )
        ) : (
          <Skeleton.Avatar
            active
            shape="circle"
            size={140}
            style={{ display: "block" }}
          />
        )}
      </div>
    </div>
  );
};

export default ProfileHeader;
