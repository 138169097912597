import React, { useEffect, useState } from "react";
import { Col, Container, Image, Row } from "react-bootstrap";
import "./ViewDigitalCard.css";
import { fetchViewDigitalCardAll } from "../../services/apiServices";
import { useParams } from "react-router-dom";
import "react-horizontal-scrolling-menu/dist/styles.css";

import { AppCardBackgroundImage, AppPageNotFound } from "../../utils/constants";
import { socialPlatforms } from "../../components/UI/view-digital-card";

import {
  handleAddToContacts,
  handleShareButtonClick,
  openSocialLink,
} from "./helper";

import CardShare from "./CardShare";
import CompanyDetails from "./CompanyDetails";
import ProductBio from "./ProductBio";
import SocialMedia from "./SocialMedia";
import ProfileHeader from "./ProfileHeader";
import CardQr from "./CardQr";
import ProfileInfo from "./ProfileInfo";

function ViewDigitalCard() {
  const { companyName, cardReference } = useParams();

  const [isFetchingCard, setIsFetchingCard] = useState(true);
  const [cardDetails, setCardDetails] = useState({});
  const [imageUrl, setImageUrl] = useState("");

  useEffect(() => {
    async function fetchCard() {
      try {
        setIsFetchingCard(true);
        let queries = { companyName, cardReference };
        const response = await fetchViewDigitalCardAll(queries);
        if (response.status === 200) {
          setCardDetails(response.data.data);
          setImageUrl(response.data.data.profile_picture);
        } else if (response.status === 404) {
          setCardDetails({});
        } else {
        }
      } catch (error) {
        console.error("Error fetching card details:", error);
      } finally {
        setIsFetchingCard(false);
      }
    }

    fetchCard();
  }, [companyName, cardReference]);

  return (
    <>
      <Container
        fluid
        className="text-white bg-black"
        // style={{
        //   backgroundImage: `url(${AppCardBackgroundImage})`,
        //   backgroundSize: "cover",
        //   backgroundPosition: "center",
        // }}
      >
        <Row className="m-0 p-0">
          <Col lg={6} md={6} sm={0} xs={0} className="d-none d-md-block">
            {/* <div className="d-flex flex-column justify-content-center align-items-center text-center">
              {Object.keys(cardDetails).length === 0 && !isFetchingCard ? (
                <>
                  <Image
                    src={AppPageNotFound}
                    width={"100%"}
                    className="mx-2 my-5"
                  />
                </>
              ) : (
                <Image
                  className="mt-5 rounded-4"
                  src={cardDetails?.company_logo}
                  height={100}
                  width={100}
                  alt="Company Logo"
                />
              )}

              <div className="fs-4 fw-bold my-2">
                <div className="">Tap, Connect, Cultivate</div>
                <div className="">Where Business Networking Blossoms </div>
              </div>
            </div> */}
          </Col>

          <Col
            lg={4}
            md={4}
            sm={12}
            xs={12}
            className="m-0 p-0 py-3 py-lg-5 rounded-4"
          >
            {Object.keys(cardDetails).length === 0 && !isFetchingCard ? (
              <div>
                <Image
                  src={AppPageNotFound}
                  width={"100%"}
                  className="mx-2 my-5"
                />
                <p className="text-center fw-bolder fs-5 mx-4 my-5">
                  Card Not Found with Requested Company And Card Reference
                </p>
              </div>
            ) : (
              <Row className="m-0 p-0 bg-white g-4 rounded-4">
                <ProfileHeader
                  cardDetails={cardDetails}
                  imageUrl={imageUrl}
                  isFetchingCard={isFetchingCard}
                />

                <ProfileInfo
                  cardDetails={cardDetails}
                  isFetchingCard={isFetchingCard}
                />

                <CompanyDetails
                  cardDetails={cardDetails}
                  isFetchingCard={isFetchingCard}
                />
                <SocialMedia
                  cardDetails={cardDetails}
                  socialPlatforms={socialPlatforms}
                  openSocialLink={openSocialLink}
                />
                <ProductBio
                  cardDetails={cardDetails}
                  isFetchingCard={isFetchingCard}
                />

                <CardQr cardDetails={cardDetails} />

                <div
                  style={{
                    position: "sticky",
                    bottom: 0,
                    zIndex: 1000,
                  }}
                  className="bg-white rounded-4"
                >
                  <CardShare
                    handleShareButtonClick={handleShareButtonClick}
                    handleAddToContacts={handleAddToContacts}
                    cardDetails={cardDetails}
                  />
                </div>
              </Row>
            )}
          </Col>
          <Col lg={2} md={2} sm={0} xs={0}></Col>
        </Row>
        <footer className="p-2 text-center fw-bold">
          <p className="text-center my-2">
            {" "}
            © 2024 Powered by midin.app. All Rights Reserved.
          </p>
        </footer>
      </Container>
    </>
  );
}

export default ViewDigitalCard;
