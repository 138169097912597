import {
  CreditCardOutlined,
  PlusOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { FaListOl } from "react-icons/fa6";
import {
  MdOutlineAddBusiness,
  MdOutlineDashboard,
  MdOutlinePassword,
  MdOutlineLibraryAdd,
} from "react-icons/md";
import { AiOutlineSecurityScan } from "react-icons/ai";
import { TbChecklist } from "react-icons/tb";

import { PiCardsBold } from "react-icons/pi";

export const dashboardItems = [
  {
    key: "1",
    icon: <MdOutlineDashboard className="fs-4" />,
    label: "Company Details",
  },
  {
    key: "2",
    icon: <MdOutlineLibraryAdd className="fs-4" />,
    label: "Create Card",
  },
  { key: "3", icon: <PiCardsBold className="fs-4" />, label: "Cards" },
  // { key: "4", icon: <QrcodeOutlined />, label: "Cards QRs" },
  // {
  //   key: "5",
  //   icon: <AiOutlineSecurityScan className="fs-4" />,
  //   label: "Profile",
  // },
  {
    key: "6",
    icon: <AiOutlineSecurityScan className="fs-4" />,
    label: "Change Password",
  },
  {
    key: "7",
    icon: <MdOutlineAddBusiness className="fs-4" />,
    label: "Add Branch",
  },
  { key: "8", icon: <TbChecklist className="fs-4" />, label: "Branch List" },
];
