import { Col, Image, Row } from "react-bootstrap";
import {
  FacebookCustomIcon,
  InstagramCustomIcon,
  LineCustomIcon,
  LinkedInCustomIcon,
  TelegramCustomIcon,
  TikTokCustomIcon,
  TwitterCustomIcon,
  WebsiteCustomIcon,
  WeChatCustomIcon,
  WeiboCustomIcon,
  WhatsAppCustomIcon,
  XHSCustomIcon,
  YouTubeCustomIcon,
} from "../../utils/constants";
import { Button } from "antd";

export const socialPlatforms = [
  "official_website",
  "facebook",
  "instagram",
  "linkedin",
  "whatsapp",
  "telegram",
  "we_chat",
  "line",
  "youtube",
  "twitter",
  "tiktok",
  "xiao_hong_shu",
  "weibo",
];

export const platformDetails = {
  official_website: { label: "Website", icon: WebsiteCustomIcon },
  facebook: { label: "Facebook", icon: FacebookCustomIcon },
  instagram: { label: "Instagram", icon: InstagramCustomIcon },
  linkedin: { label: "LinkedIn", icon: LinkedInCustomIcon },
  whatsapp: { label: "WhatsApp", icon: WhatsAppCustomIcon },
  telegram: { label: "Telegram", icon: TelegramCustomIcon },
  we_chat: { label: "WeChat", icon: WeChatCustomIcon },
  line: { label: "Line", icon: LineCustomIcon },
  youtube: { label: "YouTube", icon: YouTubeCustomIcon },
  twitter: { label: "Twitter", icon: TwitterCustomIcon },
  tiktok: { label: "TikTok", icon: TikTokCustomIcon },
  xiao_hong_shu: { label: "XiaoHongShu", icon: XHSCustomIcon },
  weibo: { label: "Weibo", icon: WeiboCustomIcon },
};

export const SocialMediaButton = ({ platform, link, onClick = () => {} }) => {
  const { label, icon } = platformDetails[platform];

  return (
    // <div lg={6} md={6} sm={6} xs={6} className="">
    //   <Button
    //     variant="outline-none"
    //     className="w-100 d-flex align-items-center border-0"
    //     size="large"
    //     onClick={() => onClick(link)}
    //   >
    //     <div
    //       className="m-0 p-0 w-10 "
    //       style={{ border: "1px solid blue", msTextCombineHorizontal: "none" }}
    //     >
    //       <div
    //         lg={3}
    //         md={3}
    //         sm={3}
    //         xs={3}
    //         className="m-0 p-0 d-flex align-items-center "
    //         style={{ border: "1px solid green" }}
    //       >
    //         <Image
    //           fluid
    //           src={icon}
    //           alt={`${label} Icon`}
    //           height={"500%"}
    //           width={"500%"}
    //           // style={{
    //           //   maxHeight: "100%",
    //           //   maxWidth: "100%",
    //           // }}
    //         />
    //       </div>
    //     </div>
    //   </Button>
    // </div>
    <div lg={6} md={6} sm={6} xs={6} className="">
      <Button
        variant="outline-none"
        className="w-100 d-flex align-items-center justify-content-center border-0 shadow-none"
        size="large"
        onClick={() => onClick(link)}
      >
        <div
          className="m-0 p-0 d-flex align-items-center justify-content-center"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            overflow: "hidden",
            backgroundColor: "white",
          }}
        >
          <Image
            fluid
            src={icon}
            alt={`${label} Icon`}
            style={{
              width: "100%",
              height: "100%",
              objectFit: "contain",
            }}
          />
        </div>
      </Button>
    </div>
  );
};

const openSocialLink = (link) => {
  window.open(link, "_blank");
};

// export const CompanyInfoItem = ({ label, value, link, icon }) => {
//   return (
//     <Col lg={6} md={6} sm={6} xs={6} className="mb-3  ">
//       <div className="fw-bold">
//         {link ? (
//           <a
//             href={link}
//             className="html_link company-info-wrap"
//             target="_blank"
//             rel="noreferrer"
//           >
//             {icon &&
//               (typeof icon === "string" ? (
//                 <img
//                   src={icon}
//                   alt="icon"
//                   className="custom-icon me-2"
//                   style={{
//                     maxHeight: "30px",
//                     maxWidth: "30px",
//                   }}
//                 />
//               ) : (
//                 <span
//                   className="custom-icon me-2"
//                   style={{ fontSize: "20px", color: "#555" }}
//                 >
//                   {icon}
//                 </span>
//               ))}
//             <span className="text-black">{value || "NA"}</span>
//           </a>
//         ) : (
//           <>
//             {icon && (
//               <img
//                 src={icon}
//                 alt="icon"
//                 className="custom-icon me-1"
//                 style={{
//                   maxHeight: "100%",
//                   maxWidth: "10%",
//                 }}
//               />
//             )}
//             <span className="text-black">{value || "NA"}</span>
//           </>
//         )}
//       </div>
//       <small className="text-muted fw-bold">{label}</small>
//     </Col>
//   );
// };

export const CompanyInfoItem = ({ label, value, link, icon }) => {
  return (
    <div className="d-flex align-items-start">
      {icon && (
        <div
          className="custom-icon-bg d-flex align-items-center justify-content-center flex-shrink-0 me-3"
          style={{
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            background: "linear-gradient(150deg, #12A1A3, #076263, #023c3c)",
          }}
        >
          {typeof icon === "string" ? (
            <img
              src={icon}
              alt="icon"
              style={{
                maxWidth: "70%",
                maxHeight: "70%",
              }}
            />
          ) : (
            <span style={{ fontSize: "24px", color: "white" }}>{icon}</span>
          )}
        </div>
      )}
      <div style={{ flex: "1 1 0" }}>
        {link ? (
          <a
            href={link}
            className="html_link company-info-wrap text-decoration-none"
            target="_blank"
            rel="noreferrer"
            style={{ wordBreak: "break-word" }}
          >
            <span className="text-black d-block">{value || "NA"}</span>
          </a>
        ) : (
          <span
            className="text-black d-block"
            style={{ wordBreak: "break-word" }}
          >
            {value || "NA"}
          </span>
        )}
        <small className="text-muted fw-bold d-block">{label}</small>
      </div>
    </div>
  );
};
