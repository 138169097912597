import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Input, Select, Upload, message } from "antd";
import { Col, Row } from "react-bootstrap";
import {
  UploadOutlined,
  DownloadOutlined,
  MailOutlined,
  PlusOutlined,
} from "@ant-design/icons";
import { Editor } from "@tinymce/tinymce-react";
import ImgCrop from "antd-img-crop";
import { useNavigate } from "react-router-dom";
import { UserOutlined, PhoneOutlined } from "@ant-design/icons";

import "./ViewCreateCard.css";
import ViewBusinessCardPreviewModal from "./ViewBusinessCardPreviewModal";
import MidinFooter from "../../MidinFooter/MidinFooter";
import {
  downloadSampleExcel,
  editorApiKey,
  initialBusinessCardData,
} from "../../../utils/constants";
import {
  createMultipleBusinessCard,
  getCompanyDetailsLists,
} from "../../../services/apiServices";
import { handleAuthenticationError } from "../../../utils/authHelpers";
import { validationRules } from "../../../utils/validationRules";
import BreadcrumbComponent from "../../UI/bread-crumb";

function ViewCreateCard() {
  const navigate = useNavigate();

  const [businessCardData, setBusinessCardData] = useState(
    initialBusinessCardData
  );
  const [isPreviewModalVisible, setIsPreviewModalVisible] = useState(false);
  const [fileList, setFileList] = useState([]);
  const [previewImageUrl, setPreviewImageUrl] = useState("");
  const [bioHtml, setBioHtml] = useState("");
  const [branches, setBranches] = useState([]);

  const [form] = Form.useForm();
  const formRef = React.createRef();
  const { Option } = Select;

  const onChange = async ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const onPreview = async (file) => {
    let src = file.url || file.preview;

    if (!src && file.originFileObj) {
      src = await new Promise((resolve) => {
        const reader = new FileReader();
        reader.readAsDataURL(file.originFileObj);
        reader.onload = () => resolve(reader.result);
      });
    }

    setPreviewImageUrl(src);
  };

  const onFinish = (values) => {
    setBusinessCardData({
      ...businessCardData,
      ...values,
    });

    handlePreviewModalOpen();
  };

  const handleExcelUpload = async (file) => {
    const allowedExtensions = [".xlsx", ".xls"];
    const fileExtension = file.name
      .slice(file.name.lastIndexOf("."))
      .toLowerCase();

    if (!allowedExtensions.includes(fileExtension)) {
      message.error("Only .xlsx and .xls files are allowed.");
      return;
    }
    try {
      let fileData = { file: file, fieldName: "file" };
      const response = await createMultipleBusinessCard(fileData);
      if (response.status === 201) {
        message.success(response.data.message);
      } else if (response.status === 401) {
        handleAuthenticationError(response.data.message, navigate);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("Error uploading file:", error.message);
      message.error("Error While Creating Cards from File.", error.message);
    }
  };

  const handlePreviewModalOpen = () => {
    setIsPreviewModalVisible(true);
  };

  const handlePreviewModalClose = () => {
    setIsPreviewModalVisible(false);
  };

  const handleEditorChange = (content, editor) => {
    setBioHtml(content);
  };

  const getBranches = useCallback(async () => {
    try {
      const response = await getCompanyDetailsLists();
      if (response.status === 200) {
        setBranches(response.data.data);
      } else if (response.status === 401) {
        handleAuthenticationError(response.data.message, navigate);
      } else {
        message.error(response.data.message);
      }
    } catch (error) {
      console.error("API request failed:", error);
      message.error("Failed to fetch branches. Please try again later.");
    } finally {
    }
  }, [navigate]);

  useEffect(() => {
    getBranches();
  }, [navigate, getBranches]);

  const renderOptionLabel = (branch) => (
    <span>
      <div className="text-wrap">
        {branch.company_name} - {branch.company_address}
      </div>
    </span>
  );

  return (
    <>
      <BreadcrumbComponent
        breadcrumbItems={[
          { title: "Home" },
          { title: "Create Card", isActive: true },
        ]}
      />
      <Row>
        <Col lg={6} md={6} sm={12} xs={12}>
          <h2 className="mx-4">Create Card</h2>
          <p className="mx-4">
            Quickly generate unique employee cards for better organization
          </p>
        </Col>
        <Col lg={6} md={6} sm={12} xs={12}>
          <Row className="align-items-center mx-3">
            <Col
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="mt-2 d-flex justify-content-center justify-content-lg-start"
            >
              <Upload
                accept=".xlsx, .xls"
                beforeUpload={handleExcelUpload}
                showUploadList={false}
              >
                <Button
                  type="primary"
                  className="w-100"
                  size="large"
                  style={{
                    backgroundColor: "#12A1A3",
                    borderColor: "#12A1A3",
                  }}
                >
                  <PlusOutlined /> Create Multiple Cards
                </Button>
              </Upload>
            </Col>

            <Col
              lg={6}
              md={6}
              sm={12}
              xs={12}
              className="mt-2 mx-0 d-flex justify-content-center justify-content-lg-start"
            >
              <a href={downloadSampleExcel} target="_blank" rel="noreferrer">
                <Button
                  type="primary"
                  className="w-100"
                  size="large"
                  style={{
                    backgroundColor: "#12A1A3",
                    borderColor: "#12A1A3",
                  }}
                >
                  <DownloadOutlined /> Download Sample File
                </Button>
              </a>
            </Col>
          </Row>
        </Col>
      </Row>
      <div className="viewCreateCardContainer mx-3">
        <Form
          form={form}
          ref={formRef}
          className="p-2"
          name="createCardForm"
          onFinish={onFinish}
        >
          <Row>
            <Col lg={6} md={12} sm={12}>
              <label className="fw-bold my-1">First Name</label>
              <Form.Item
                name="first_name"
                rules={validationRules.first_name}
                hasFeedback
              >
                <Input
                  placeholder="Enter First Name"
                  size="large"
                  prefix={<UserOutlined />}
                />
              </Form.Item>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <label className="fw-bold my-1">Last Name</label>
              <Form.Item
                name="last_name"
                rules={validationRules.last_name}
                hasFeedback
              >
                <Input
                  placeholder="Enter Last Name"
                  size="large"
                  prefix={<UserOutlined />}
                />
              </Form.Item>
            </Col>

            <Col lg={6} md={12} sm={12}>
              <label className="fw-bold my-1">Designation</label>
              <Form.Item
                name="designation"
                rules={validationRules.designation}
                hasFeedback
              >
                <Input
                  placeholder="Enter Designation"
                  size="large"
                  prefix={<UserOutlined />}
                />
              </Form.Item>
            </Col>

            <Col lg={6} md={12} sm={12}>
              <label className="fw-bold my-1">Email</label>
              <Form.Item
                name="user_email"
                rules={validationRules.email}
                hasFeedback
              >
                <Input
                  placeholder="Enter Email Address"
                  size="large"
                  prefix={<MailOutlined />}
                />
              </Form.Item>
            </Col>

            <Col lg={6} md={12} sm={12}>
              <label className="fw-bold my-1">Contact Number</label>
              <Form.Item
                name="contact_number"
                rules={validationRules.contact_number}
                hasFeedback
              >
                <Input
                  placeholder="Enter Contact Number"
                  size="large"
                  prefix={<PhoneOutlined />}
                />
              </Form.Item>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <label className="fw-bold my-1">WhatsApp Number</label>
              <Form.Item
                name="personal_whatsapp"
                rules={validationRules.personal_whatsapp}
                hasFeedback
              >
                <Input
                  placeholder="Enter WhatsApp Number"
                  size="large"
                  prefix={<PhoneOutlined />}
                />
              </Form.Item>
            </Col>

            <Col lg={12} md={6} sm={6}>
              <label className="fw-bold my-2">Select Office Address</label>
              <Form.Item
                name="associated_company"
                rules={validationRules.associated_company}
                hasFeedback
              >
                <Select
                  showSearch
                  style={{ width: "100%", height: "max-content" }}
                  placeholder="Select a Branch"
                  size="large"
                  optionFilterProp="label"
                  filterOption={(input, option) =>
                    option.label.toLowerCase().includes(input.toLowerCase())
                  }
                  filterSort={(optionA, optionB) =>
                    optionA.label
                      .toLowerCase()
                      .localeCompare(optionB.label.toLowerCase())
                  }
                >
                  {branches &&
                    branches.map((branch) => (
                      <Option
                        key={branch.id}
                        value={branch.id}
                        label={branch.company_name}
                      >
                        {renderOptionLabel(branch)}
                      </Option>
                    ))}
                </Select>
              </Form.Item>
            </Col>

            {/* <Col lg={6} md={6} sm={6}>
              <label className="fw-bold my-1">Profile Picture</label>
              <ImgCrop rotationSlider showReset>
                <Upload
                  hasControlInside
                  accept="image/*"
                  listType="picture-card"
                  fileList={fileList}
                  onChange={onChange}
                  onPreview={onPreview}
                  maxCount={1}
                  customRequest={({ file, onSuccess }) => {
                    setTimeout(() => {
                      onSuccess("ok");
                    }, 0);
                  }}
                >
                  {fileList.length < 1 && (
                    <div>
                      <UploadOutlined />
                      <div style={{ marginTop: 8 }}>Upload Profile</div>
                    </div>
                  )}
                </Upload>
              </ImgCrop>
            </Col> */}

            {/* <Col lg={12} md={12} sm={12} xs={12} className="text-center">
              <label className="fw-bold mb-3 d-block align-text-start fs-5">
                Profile Picture
              </label>
              <ImgCrop rotationSlider showReset>
                <Upload
                  accept="image/*"
                  listType="text"
                  fileList={fileList}
                  onChange={onChange}
                  onPreview={onPreview}
                  maxCount={1}
                  customRequest={({ file, onSuccess }) => {
                    setTimeout(() => {
                      onSuccess("ok");
                    }, 0);
                  }}
                >
                  {fileList.length < 1 && (
                    <div className="border p-4 rounded bg-light mx-auto">
                      <UploadOutlined className="fs-2 text-muted" />
                      <div className="mt-3 text-muted">
                        Drag & drop files here or
                        <span
                          className="text-primary "
                          style={{ cursor: "pointer" }}
                        >
                          Browse files
                        </span>
                      </div>
                    </div>
                  )}
                </Upload>
              </ImgCrop>
            </Col> */}
            <Col lg={8} md={10} sm={12} xs={12} className="mx-auto text-center">
              <label className="fw-bold mb-3 d-block align-text-start fs-5">
                Profile Picture
              </label>
              <ImgCrop rotationSlider showReset>
                <Upload
                  accept="image/*"
                  listType="text"
                  fileList={fileList}
                  onChange={onChange}
                  onPreview={onPreview}
                  maxCount={1}
                  customRequest={({ file, onSuccess }) => {
                    setTimeout(() => {
                      onSuccess("ok");
                    }, 0);
                  }}
                >
                  {fileList.length < 1 && (
                    <div
                      style={{
                        cursor: "pointer",
                      }}
                      className="border border-2 p-2 rounded-4 d-flex align-items-center justify-content-center"
                    >
                      <UploadOutlined
                        style={{
                          fontSize: "24px",
                          marginRight: "8px",
                          cursor: "pointer",
                        }}
                      />
                      Upload Profile Picture
                    </div>
                  )}
                </Upload>
              </ImgCrop>
            </Col>
          </Row>

          <Col lg={12} md={12} sm={12}>
            <label className="fw-bold my-1">Bio</label>
            <Form.Item className="quill-editor">
              <Editor
                apiKey={editorApiKey}
                init={{
                  placeholder: "Enter Bio Details",
                  plugins:
                    "anchor autolink charmap codesample emoticons image link searchreplace table visualblocks wordcount lists fontsize fontfamily",
                  toolbar:
                    "undo redo | fontfamily fontsize | bold italic underline | image media | align lineheight | numlist bullist indent outdent | emoticons ",
                  images_default_resizing: "scale",
                  images_resizing: true,
                  file_picker_types: "image",
                  file_picker_callback: function (callback, value, meta) {
                    if (meta.filetype === "image") {
                      var input = document.createElement("input");
                      input.setAttribute("type", "file");
                      input.setAttribute("accept", "image/*");

                      input.onchange = function () {
                        var file = this.files[0];
                        var reader = new FileReader();

                        reader.onload = function (e) {
                          callback(e.target.result, {
                            alt: file.name,
                          });
                        };

                        reader.readAsDataURL(file);
                      };

                      input.click();
                    }
                  },
                  media_live_embeds: true,
                  media_embeds: true,
                  content_css: "tinymce-5",
                }}
                onEditorChange={handleEditorChange}
                value={bioHtml}
              />
            </Form.Item>
          </Col>

          <Row>
            <Col></Col>
            <Col>
              <Button
                type="primary"
                htmlType="submit"
                size="large"
                className="w-100"
              >
                Preview Card
              </Button>
            </Col>
            <Col></Col>
          </Row>
        </Form>
      </div>

      <MidinFooter />

      <ViewBusinessCardPreviewModal
        isVisible={isPreviewModalVisible}
        onClose={handlePreviewModalClose}
        data={businessCardData}
        imageUrl={previewImageUrl}
        fileList={fileList}
        onSuccess={(cardDetails) => {
          formRef.current.resetFields();
          form.resetFields();
          setFileList([]);
          setBioHtml("");
        }}
        bioHtml={bioHtml}
      />
    </>
  );
}

export default ViewCreateCard;
