import React from "react";
import { Breadcrumb } from "antd";
import { RightOutlined } from "@ant-design/icons";

const BreadcrumbComponent = ({ breadcrumbItems }) => {
  return (
    <Breadcrumb className="mx-4 my-5 my-lg-2" separator={<RightOutlined />}>
      {breadcrumbItems.map((item, index) => (
        <Breadcrumb.Item key={index}>
          <span style={item.isActive ? { color: "#12A1A3" } : {}}>
            {item.title}
          </span>
        </Breadcrumb.Item>
      ))}
    </Breadcrumb>
  );
};

export default BreadcrumbComponent;
